import React, { useEffect, useState,useRef } from "react";
import axios from '../../../helpers/Axios'
import DataTable, { createTheme } from "react-data-table-component";
import m from './upcoming.module.css'

import dayjs from 'dayjs';

function History() {
  const [alldata,setAlldata]=useState([])
  const [recive, setRecive] = useState([]);
 
  const [today,setToday]=useState([])
  let tab1 = useRef()
  let tab2 = useRef()
const [modalShow, setModalShow] = React.useState(false);
const [isTab, setIsTab] = useState(false);

  function gettingBrandicon() {
 
    axios
      .get(`/payment/success?user_id=${localStorage.USER_PHONE_NO}`)
      .then((res) => {
        // setAlldata(res.data.users);
        setRecive(res.data.user);
        // console.log(res.data.user,"received brand Icons ");
      
        const todaydate = new Date( dayjs().$d.toLocaleDateString().split('/').join('-'))
        const formattedDate = new Date(dayjs().format('DD-MM-YYYY'))
        //  console.log("todayday",todaydate,formattedDate)
        const arr =[]
        for(let i=0;i<(res.data.user).length;i++)
        {
            const booking_summary = JSON.parse(res.data.user[i].booking_summary)
            const mm = new Date((booking_summary.startDate))

            //new date mm/dd/yyyy
            const inputDate = booking_summary.startDate;
            const parsedDate = dayjs(inputDate, 'DD-MM-YYYY');
            const formattedDate =  new Date(parsedDate.format('MM/DD/YYYY').split('/').join('-'));
           
       if(mm != "Invalid Date"){
             
        // console.log(mm,"olddddddddddddd")
        if(todaydate > mm ){
          // console.log([i])
            arr.push(res.data.user[i])
        }
        else{
            
            // console.log("flase")

        }
       }
       else {
        if(todaydate > formattedDate ){
          // console.log([i])
            arr.push(res.data.user[i])
        }
        else{
            
            // console.log("flase")

        }
           
            // console.log(formattedDate,"ssssssssssssssssssss");
       }






        if(todaydate > mm ){
          // console.log([i])
            arr.push(res.data.user[i])
        }
        else{
            
            // console.log("flase")

        }
        
        }
        setAlldata(arr);

      })
      .catch(() => {
        console.log("error receiveing brand icons deatils ");
      });
  }
  useEffect(() => {
    gettingBrandicon();
  }, []);
  function filter(){
 
    // const booking_summary = JSON.parse(alldata[0].booking_summary);
    // const startdate =booking_summary["startDate"]
    // console.log(alldata,'rrrrrrrrrrr')
// const filterdata=[]

for(let i=0;i<1;i++)
{
    // const type1 ="Standard"
    


}

  }
 

  useEffect(() => {
  filter();
  }, []);

  // console.log()
//   createTheme("fulldark", {
//     text: {
//       primary: "white",
//       secondary: "white",
//     },
//     background: {
//     //   default: "#212529",
//      default: "#000000",
//     },
//     context: {
//       text: "white",
//     },
//     button: {
//       default: "white",
//       focus: "white",
//       hover: "white",
//       disabled: "white",
//     },
//   });
 createTheme("fulldark", {
    text: {
      primary: "white",
      secondary: "white",
    },
    background: {
      default: "#000000",
    },
    context: {
      text: "white",
    },
    button: {
      default: "white",
      focus: "white",
      hover: "white",
      disabled: "white",
    },
    // Adding border to the theme
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            border: '1px solid gray',
          },
        },
      },
    },
  });
  const columns = [
    {
      name: <p>Id</p>,
      selector: (row) => row.booking_id,
    },
    {
      name: <p> Car name</p>,
      cell: (row) => {
        const booking_summary = JSON.parse(row.booking_summary);
        // console.log(booking_summary,'yyyyyyyyyyyyyy')
        return (
        
            <p>{booking_summary["name"]}</p>
         
        );
      },

    },
    {
      name: <p>Status</p>,
      selector: (row) => row.payment_status,
    },
    {
    
      name: <p> Type</p>,
      cell: (row) => {
        const  userdetails = JSON.parse(row.user_details)
    
        return (
            <p>{userdetails["package"]}</p>
        );
      },

  },
  {
    
    name: <p> Date </p>,
    cell: (row) => {
      const booking_summary = JSON.parse(row.booking_summary);
       
      const mp =booking_summary["endDate"]
      return (
        <div>
        { mp ?
      <div>
       <p>{booking_summary["startDate"]+"-"+booking_summary["startTime"]}</p>
      <p>{booking_summary["endDate"]+"-"+booking_summary["endTime"]}</p>
      </div>
       :<p>{booking_summary["startDate"]+"-"+booking_summary["startTime"]}</p>

        }

        </div>
      );
    },

  },
  {
    
    name: <p> Amount </p>,
    cell: (row) => {
      const booking_summary = JSON.parse(row.user_details);
      return (
        <p>{booking_summary["price"]}</p>
        
      );
    },

  },

  ]
  
  return (
    <div>
    <div  className={m.name}>
      <DataTable
      columns={columns}
      data={alldata}
      theme="fulldark"
    />
   </div>
    </div>
  )
}

export default History