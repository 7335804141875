import React, { useEffect, useState,useRef } from 'react'
import { Link } from 'react-router-dom'
import s from './nav_foo.module.css'
import { FaTwitter,FaFacebook,FaLinkedin,FaInstagram } from 'react-icons/fa';
import EmailSubscriptionForm from './Emailsubscription';
import FAQ from './FAQ'
import WBL from './whyblckluxury'

function Footer() {
    

    const items = {
        'Airport pickup/drop luxury car bangalore': 'https://blckluxury.com/luxury-car-rental-in-Bangalore',
        'Airport pickup/drop luxury car chennai': 'https://blckluxury.com/luxury-car-rental-in-Chennai',
        'Airport pickup/drop luxury car delhi': 'https://blckluxury.com/luxury-car-rental-in-Delhi',
        'Airport pickup/drop luxury car hyderabad': 'https://blckluxury.com/luxury-car-rental-in-Hyderabad',
        'Airport pickup/drop luxury car mumbai': 'https://blckluxury.com/luxury-car-rental-in-Mumbai',
        'Chauffeur driven luxury cars in bangalore': 'https://blckluxury.com/luxury-car-rental-in-Bangalore',
        'Chauffeur driven luxury cars in mumbai': 'https://blckluxury.com/luxury-car-rental-in-Mumbai',
        'Luxury car rental ahmedabad': 'https://blckluxury.com/luxury-car-rental-in-Ahmedabad',
        'Luxury car rental chandigarh': 'https://blckluxury.com/luxury-car-rental-in-Chandigarh',
        'Luxury car rental chennai': 'https://blckluxury.com/luxury-car-rental-in-Chennai',
        'Luxury car rental coimbatore': 'https://blckluxury.com/luxury-car-rental-in-Coimbatore',
        'Luxury car rental delhi': 'https://blckluxury.com/luxury-car-rental-in-Delhi',
        'Luxury car rental goa': 'https://blckluxury.com/luxury-car-rental-in-Goa',
        'Luxury car rental hyderabad': 'https://blckluxury.com/luxury-car-rental-in-Hyderabad',
        'Luxury car rental in mumbai': 'https://blckluxury.com/luxury-car-rental-in-Mumbai',
        'Luxury car rental udaipur': 'https://blckluxury.com/luxury-car-rental-in-Udaipur',
        'Luxury car rental jaipur': 'https://blckluxury.com/luxury-car-rental-in-Jaipur',
        'Luxury car rental Pune': 'https://blckluxury.com/luxury-car-rental-in-Pune',
        'Self drive luxury cars in delhi': 'https://blckluxury.com/luxury-car-rental-in-Delhi',
        'Self drive luxury cars in goa': 'https://blckluxury.com/luxury-car-rental-in-Goa',
        'Self drive luxury cars in hyderabad': 'https://blckluxury.com/luxury-car-rental-in-Hyderabad',
        'Self drive luxury cars in mumbai': 'https://blckluxury.com/luxury-car-rental-in-Mumbai',
        'Self driven luxury car rental bangalore': 'https://blckluxury.com/self-driven-luxury-car-rental-in-Bangalore',
        'Self driven luxury cars in chandigarh': 'https://blckluxury.com/self-driven-luxury-cars-in-Chandigarh',
        'Self driven luxury cars in coimbatore': 'https://blckluxury.com/self-driven-luxury-cars-in-Coimbatore',
        'Luxury car rental in dubai': 'https://blckluxury.com/luxury-car-rental-in-Dubai',
        'Self drive car rental in Dubai': 'https://blckluxury.com/self-drive-car-rental-in-Dubai',
      };
    return (
        <footer className={s.footer}>

        
        <div className={`${s.flexcontainer}`}>
        {Object.entries(items).map(([item, url], index) => (
          <div key={index} className={`${s.flexitem}`}>
          <a href={url}>{item}</a>
          </div>
        ))}
      </div>
        
            <div className={s.fooElements}>
                <div className={`${s.section} ${s.logoSection}`}>
                <Link to={'/'}><img src={require('../../assets/images/logo/2.png')} alt="Logo" /> </Link>    
                           <p>Copyright &#169; 2023</p>
                    <h3 className={`${s.contact}`}>+91-9900085611</h3>
                </div>
                <div className={`${s.section} ${s.companySection}`}>
                    <span>Company</span>
                    <ul>
                        <li><Link to={"/about_us"}>About Us</Link></li>
                        <li><Link to={'*'}>Careers</Link></li>
                        <li><Link to={"/partner_with_us"}>Partner With Us</Link></li>
                        <li><Link to={"/feedback"}>Feedback</Link></li>
                       
                        <li><a href="/Main_blog">News & Blogs</a></li>
                    </ul>
                </div>
                <div className={`${s.section} ${s.helpSection}`}>
                    <span>Help & Legal</span>
                    <ul>
                        {/* <li>Contact Us</li> */}
                        <li><Link to={"/contactus"}>Contact Us</Link></li>
                        <li><Link to={'/terms-and-conditions'}>Terms & Conditions</Link></li>
                        <li><Link to={'/refund-and-cancellations'}>Refund & Cancellation Policy</Link></li>
                        <li><Link to={'/privacy-policy'}>Privacy Policy</Link></li>
                        <li><Link to={'*'}>Cookies</Link></li>
                    </ul>
                </div>
                <div className={`${s.section} ${s.followSection} ${s.logo}`}>
                    <span>Follow Us</span>
                   <ul className={`${s.socialic}`} style={{display: "-webkit-box"}}>

                        <li><a href='https://www.linkedin.com/company/blckluxury/' target="_blank" rel="noopener noreferrer"><FaLinkedin size={28} color="white" /></a></li>&nbsp;&nbsp;
                        <li><a href='https://www.facebook.com/profile.php?id=61558033071512' target="_blank" rel="noopener noreferrer"><FaFacebook size={28} color="white" /></a></li>&nbsp;&nbsp;
                        <li><a href='https://www.instagram.com/blck_luxury_india?igsh=aThrbXZ3MHExbmto&utm_source=qr' target="_blank" rel="noopener noreferrer"><FaInstagram size={28} color="white" /></a></li>&nbsp;&nbsp;
                        <li><a href='https://twitter.com/blck_luxury' target="_blank" rel="noopener noreferrer">      <FaTwitter size={28} color="white" /></a></li>

                    </ul>
                    <div>
                   
                    <EmailSubscriptionForm />
                  </div>
                    </div>
                    
            </div>
            <br/>
           
        </footer>
    )
}

export default Footer