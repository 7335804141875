import React, { useEffect, useState,useRef } from "react";
import axios from '../../../helpers/Axios'
import DataTable, { createTheme } from "react-data-table-component";
import ProfileTab from './completed'
import HistoryTab from './upcoming'
import s from '../profile.module.css'
import c from '../../../assets/css/custom.module.css'
import m from './upcoming.module.css'



function Historyab() {

  const [recive, setRecive] = useState([]);
  let tab1 = useRef()
  let tab2 = useRef()
const [modalShow, setModalShow] = React.useState(false);
const [isTab, setIsTab] = useState(true);

  function gettingBrandicon() {
    axios
      .get("/payment/success")
      .then((res) => {
        setRecive(res.data.users);
        // console.log(res.data.users);
        // console.log("received brand Icons ");
      })
      .catch(() => {
        // console.log("error receiveing brand icons deatils ");
      });
  }
  useEffect(() => {
    // gettingBrandicon();
  }, [modalShow]);

  return (
    <div>
    <div className={`${m.tabs}`}>
        <div ref={tab1} className={`${s.tab}`}
        onClick={()=>{
          setIsTab(false);
          tab1.current.classList.add(`${s.selected}`);
          tab2.current.classList.remove(`${s.selected}`);
          }}>Upcoming</div>
        <div className={`${s.tabLine}`}></div>
        <div ref={tab2} className={`${s.tab}`}
        onClick={()=>{
          setIsTab(true);
          tab1.current.classList.remove(`${s.selected}`);
          tab2.current.classList.add(`${s.selected}`);
          }}>Completed</div>
      </div>
      <div 
      className={`${m.tabBody}`} 
      
      >
        {/* {isTab ? <upcoming/> : <ProfileTab/>} */}
        {isTab ? <ProfileTab/> : <HistoryTab/>}
      </div>
      {/* {isTab ? <button   className={m.primaryBtnFilled} >Edit</button> : <></> }
      {isTab ? <button   className={m.primaryBtnFilled01}>Cancel</button> : <></> } */}
  </div>
  )
}

export default Historyab