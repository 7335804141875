import React, { useState, useEffect } from 'react';
import './TimeSelector.css';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

const TimeSelector = ({ onTimeSelectend = () => {}, showPopupend, setShowPopupend, datend }) => {
    const [selectedHour, setSelectedHour] = useState('00');
    const [selectedMinute, setSelectedMinute] = useState('00');
    const [currentHour, setCurrentHour] = useState('00');
    const [currentMinute, setCurrentMinute] = useState('00');

    useEffect(() => {
        const now = new Date();
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        setCurrentHour(hours);
        setCurrentMinute(minutes);
    }, []);

    const hours = Array.from({ length: 24 }, (_, i) => i.toString().padStart(2, '0'));
    const minutes = ['00', '15', '30'];

    const handleSelectTime = () => {

        setShowPopupend(false);
        const selectedTime = `${selectedHour}:${selectedMinute}`;
        document.getElementById('timeButtonend').textContent = selectedTime;
        console.log(selectedTime,'cccccccccccccccccccccc');

        onTimeSelectend(selectedTime);
    };

    const isDisabled = (hour, minute) => {
        if (!datend) return false; 

        const currentdate = `${dayjs().date()}-${dayjs().month() + 1}-${dayjs().year()}`.replace(/\s/g, '');
        const selectedDate = `${datend.date()}-${datend.month() + 1}-${datend.year()}`.replace(/\s/g, '');
        if (currentdate === selectedDate) {
            const adjustedCurrentHour = parseInt(currentHour) + 4;
            if (parseInt(hour) < adjustedCurrentHour) return true;
            if (parseInt(hour) === adjustedCurrentHour && parseInt(minute) < parseInt(currentMinute)) return true;
        }
        return false;
    };

    return (
        <div className="time-selector">
            <button id="timeButtonend" onClick={() => setShowPopupend(true)}>Choose Time</button>
            {showPopupend && (
                <div className="popup">
                    <div className="popup-content">
                        <span className="close" onClick={() => setShowPopupend(false)}>&times;</span>
                        <div className="flex gap-10 sm:gap-4 time-select">
                        <div className="h-[280px] 4k:h-[420px] sm:h-[250px] flex items-center flex-col mx-auto w-[100%] overflow-y-scroll no-scrollbar overflow-x-hidden scroll-select">
                        {hours.map(hour => (
                                    <div
                                        key={hour}
                                        className={`py-2 sm:text-[18px] 4k:text-[36px] text-[24px] cursor-pointer duration-300 text-secondarytext time-option ${selectedHour === hour ? 'selected' : ''} ${isDisabled(hour, '00') ? 'disabled' : ''}`}
                                        onClick={() => !isDisabled(hour, '00') && setSelectedHour(hour)}
                                    >
                                        {hour}
                                    </div>
                                ))}
                            </div>
                            <div className="divider"></div>
                            <div className="h-[280px] flex items-center flex-col mx-auto w-[100%] overflow-y-scroll no-scrollbar overflow-x-hidden scroll-select">
                                {minutes.map(minute => (
                                    <div
                                        key={minute}
                                        className={`py-2 sm:text-[18px] 4k:text-[36px] 4k:hover:text-[44px] text-[24px] cursor-pointer duration-300 text-button_blue 4k:text-[44px] sm:text-[28px] text-[36px] time-option ${selectedMinute === minute ? 'selected' : ''} ${isDisabled(selectedHour, minute) ? 'disabled' : ''}`}
                                        onClick={() => !isDisabled(selectedHour, minute) && setSelectedMinute(minute)}
                                    >
                                        {minute}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex justify-around sm:text-[14px] 4k:text-[24px] sm:mt-4 mt-8 popup-actions">
                            <button onClick={() => setShowPopupend(false)}>Cancel</button>
                            <button onClick={handleSelectTime}>Ok</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

TimeSelector.propTypes = {
    onTimeSelect: PropTypes.func,
    showPopupend: PropTypes.bool.isRequired,
    setShowPopupend: PropTypes.func.isRequired,
    datend: PropTypes.object.isRequired,
};

export default TimeSelector;