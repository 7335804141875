import React, { useState, useRef, useEffect} from 'react';
import s from './AboutUs.module.css'; // Make sure to create a CSS file named AboutUs.css for styling

const AboutUs = () => {
  useEffect(() => {
    document.title = "Luxury Car Rentals in India | Blck Luxury | About Us";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
        metaDescription.setAttribute("content", "At Blck Luxury, we prioritize your satisfaction and aim to provide the highest level of personalized service.");
    }
}, []);

  return (
    <div className={s.aboutuscontainer}>
      <section className="section">
        <h2>Our Story</h2>
        <p>
          Founded by a team of avid car enthusiasts with a shared vision for redefining luxury mobility, Blck Luxury has quickly become a symbol of excellence in the industry. Our journey began with a simple idea: to provide discerning individuals with access to the finest vehicles on the market. Over the years, we've transformed this idea into a reality, curating a fleet that represents the epitome of automotive craftsmanship.
        </p>
      </section>

      <section className="section">
        <h2>Our Vision</h2>
        <p>
          At Blck Luxury, we envision a world where luxury meets mobility effortlessly. We aim to set new standards in the industry by offering a curated selection of high-end vehicles that cater to your discerning taste. Whether you seek the thrill of a sports car, the sophistication of a limousine, or the practicality of an executive SUV, we have the perfect vehicle to elevate your journey.
        </p>
      </section>

      <section className="section">
        <h2>Why Choose Us?</h2>
        <ul>
          <li><strong>Unrivaled Fleet:</strong> Our fleet boasts an array of meticulously maintained luxury vehicles from renowned brands. From classic elegance to cutting-edge performance, our cars cater to a diverse range of preferences.</li>
          <li><strong>Exceptional Service:</strong> Our commitment to customer satisfaction is at the core of what we do. Our experienced and knowledgeable team is here to assist you at every step, ensuring your rental experience is seamless and enjoyable.</li>
          <li><strong>Flexible Rentals:</strong> Whether it's a weekend getaway, a business trip, or a special occasion, we offer flexible rental options to accommodate your needs. Choose from hourly, daily, or long-term rentals, and enjoy the freedom to create your itinerary.</li>
          <li><strong>Exclusivity:</strong> We understand the importance of privacy and exclusivity. When you rent from us, you're not just getting a car; you're gaining access to a world of luxury and prestige that's reserved for the select few.</li>
          <li><strong>Convenience:</strong> We make the rental process easy and convenient. With online reservations and delivery options, you can focus on enjoying your experience while we handle the logistics.</li>
        </ul>
      </section>

      <section className="section">
        <h2>Our Commitment</h2>
        <p>
          At Blck Luxury, we are committed to providing you with more than just a car; we offer an unforgettable experience. Our attention to detail, dedication to excellence, and passion for luxury travel set us apart. Whether you're exploring a new city, impressing clients, or celebrating a special occasion, we are here to ensure your journey is nothing short of exceptional.
        </p>
      </section>

      <section className="section">
        <p>
          Indulge in the world of luxury mobility with Blck Luxury. Discover the freedom to explore, the pleasure of driving, and the joy of arriving in style. Contact us today to embark on your next extraordinary adventure.
        </p>
      </section>
    </div>
  );
}

export default AboutUs;
