import React, { useEffect, useState,useRef } from "react";
import axios from '../../../helpers/Axios'
import DataTable, { createTheme } from "react-data-table-component";
import m from './upcoming.module.css'
import BookingsModal from "./Update";
import BookingsModal01 from "./Cancel";
import s from "./update.module.css"
import dayjs from 'dayjs';

function HistoryTab() {
  const [alldata,setAlldata]=useState([])
  const [maildetails,setmaildetails]=useState([])
  const [recive, setRecive] = useState([]);
  const [carname, setCarname] = useState([]);
  const [payment, setPayment] = useState([]);
  const [date01, setDate01] = useState([]);
  const [type, setType] = useState([]);
  const [time, setTime] = useState([]);
  const [price01, setPrice01] = useState([]);
  const [cancel, setCancel] = useState(false);

  const [checkbox, setCheckbox] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showModal01, setShowModal01] = useState(false)
  const [today,setToday]=useState([])
  let tab1 = useRef()
  let tab2 = useRef()
const [modalShow, setModalShow] = React.useState(false);
const [isTab, setIsTab] = useState(true);

  function gettingBrandicon() {
    axios
    .get(`/payment/success?user_id=${localStorage.USER_PHONE_NO}`)
    .then((res) => {
      // setAlldata(res.data.users);
      setRecive(res.data.user);
      console.log(res.data.user,"received brand Icons ");
    
      const todaydate =  new Date(dayjs().$d.toLocaleDateString().split('/').join('-'))
      // setToday(todaydate);
      // console.log("todayday",todaydate)
      const arr =[]
      for(let i=0;i<(res.data.user).length;i++)
      {
          const booking_summary = JSON.parse(res.data.user[i].booking_summary)
          const mm = new Date(booking_summary.startDate)
          const  userdetails = JSON.parse(res.data.user[i].user_details)
          const typeALL = (userdetails.Car_Type)
            //new date mm/dd/yyyy
            const inputDate = booking_summary.startDate;
            const parsedDate = dayjs(inputDate, 'DD-MM-YYYY');
            const formattedDate =  new Date(parsedDate.format('MM/DD/YYYY').split('/').join('-'));
          // console.log(mm)

          if(mm != "Invalid Date"){

            if(todaydate <= mm ){
              // console.log("true",res.data.user[i])
              arr.push(res.data.user[i])
          }
          else{
              
              // console.log("flase",res.data.user[i])
    
          }
          
          }

          
          else {

            if(todaydate <= formattedDate ){
              // console.log("true",res.data.user[i])
              arr.push(res.data.user[i])
          }
          else{
              
              // console.log("flase",res.data.user[i])
    
          } 
          }

          }
     
      setAlldata(arr);

      })
      .catch(() => {
        // console.log("");
      });
  }
  useEffect(() => {
    gettingBrandicon();
  }, []);
  function filter(){
 
    // const booking_summary = JSON.parse(alldata[0].booking_summary);
    // const startdate =booking_summary["startDate"]
 
// const filterdata=[]

for(let i=0;i<1;i++)
{
    // const type1 ="Standard"
    


}

  }
 

  useEffect(() => {
  filter();
  }, []);
  
  // const [isChecked, setIsChecked] = useState(false);
  
  // const handleCheckboxChange = () => {
  //   setIsChecked(!isChecked);
  // };

  // console.log(alldata,'1111111111111111111111111111111111')
  
  const columns = [

//     {
//       cell: (row) => {
//         const booking_summary = JSON.parse(row.booking_summary);
//        const  userdetails = JSON.parse(row.user_details)
//         return (
          
// <div>
// <label  className={m.switch}>
// <input type="checkbox" onClick={()=>{


// }}/>
//     <span className={m.slider}></span>
// </label>


// </div>




//         );
//       },

//     },
    {
      name: <p>Id</p>,
      selector: (row) => row.booking_id,
    },
    {
      name: <p> Car name</p>,
      cell: (row) => {
        const booking_summary = JSON.parse(row.booking_summary);
        // console.log(booking_summary,'yyyyyyyyyyyyyy')
        return (
        
            <p>{booking_summary["name"]}</p>
         
        );
      },

    },
    {
      name: <p>Status</p>,
      selector: (row) => row.payment_status,
    },
    {
    
    name: <p> Type</p>,
    cell: (row) => {
      const  userdetails = JSON.parse(row.user_details)
  
      return (
          <p>{userdetails["package"]}</p>
      );
    },

  },
  {
    
    name: <p> Date </p>,
    cell: (row) => {
      const booking_summary = JSON.parse(row.booking_summary);
      const mp =booking_summary["endDate"]
      return (
        <div>
        { mp ?
      <div>
       <p>{booking_summary["startDate"]+"-"+booking_summary["startTime"]}</p>
      <p>{booking_summary["endDate"]+"-"+booking_summary["endTime"]}</p>
      </div>
       :<p>{booking_summary["startDate"]+"-"+booking_summary["startTime"]}</p>

        }

        </div>
      );
    },

  },
  {
    
    name: <p> Amount </p>,
    cell: (row) => {
      const booking_summary = JSON.parse(row.user_details);
      return (
        <p>{booking_summary["price"]}</p>
        
      );
    },

  },
  {

   
    cell: (row) => {
      const booking_summary = JSON.parse(row.user_details);
      const  userdetails = JSON.parse(row.user_details)
      return (
       <div>


<button   className={m.primary} onClick={()=>{ 
  
  setShowModal(true)
  setRecive(row.id)
  setCarname(booking_summary["name"])
  setPayment(row.payment_status)
  setType("type")
  setDate01(booking_summary["date"])
  setTime(booking_summary["time"])
  setPrice01(userdetails["price"])
  setmaildetails(row)
  }
    
    } >Edit/cancel</button>
 
 {/* <button   className={m.primary} onClick={()=>{ 
  
 setShowModal01(true)
}
  
  
  }  >cancel</button> */}


       </div>
       
        
      );
    },


  }

  ]
  return (
<div  >
  <div className={m.name}>
    <DataTable
    columns={columns}
    data={alldata}
    theme="fulldark"
  />
   {
     showModal && <BookingsModal 
                    // status={updatedstatus}
                    setShow={setShowModal}
                    show={showModal}
                    carname={carname}
                    payment={payment}
                    type01={type}
                    date01={date01}
                    time01={time}
                    price01={price01}
                    maildetails={maildetails}
                    />
                  }
  {
    showModal01 && <BookingsModal01
                    // status={updatedstatus}
                    setShow={setShowModal01}
                    show={showModal01}
                    // carname={carname}
                    // payment={payment}
                    // type01={type}
                    // date01={date01}
                    // time01={time}
                    // price01={price01}
                    />
                  }
 </div>
<div>

 </div>
  </div>
  )
}

export default HistoryTab