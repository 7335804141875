
import Question from "./Question";
import data from "./data";
import "./faq.css";
import { useState } from "react";

function CustomAccordion() {
  const [questions, setQuestions] = useState(data);

  return (
    <div className="container">
      <h3>Frequently Asked Questions</h3>
      <section className="info">
        {questions.map((item) => {
          return <Question key={item.id} {...item} />;
        })}
      </section>
    </div>
  );
}

export default CustomAccordion;