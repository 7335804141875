import React,{useEffect, useState, useRef} from 'react';
import s from './feedback.module.css';
import axios from '../../../helpers/Axios';
import c from '../../../assets/css/custom.module.css'
import toast, { Toaster } from 'react-hot-toast';

const Feedback = () => {
    const [data, setData] = useState(
        {
          first_name:"",
          last_name:"",
          email: "",
          ratings: null,
          message: "",
        }
      )
    
      const firstNameRef = useRef();
      const lastNameRef = useRef();
      const emailRef = useRef();
      const ratingsRef = useRef();
      const MessageRef = useRef();
    
    
      const emailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
      const nameformat = /^[A-Za-z" "_.]+$/;
      const phoneNumPattern = /^\d{10}$/;
      const numberformat = /^[0-9]+$/;
    
    
      function clearmsg() {
        setTimeout(() => {
          firstNameRef.current.innerHTML = "";
          lastNameRef.current.innerHTML = "";
          emailRef.current.innerHTML = "";
          ratingsRef.current.innerHTML = "";
          MessageRef.current.innerHTML = "";
        }, 4000);
      }
    
      const payload = {
        ...data,
      };
    
      function handelFeedback() {
        if (data.first_name === "") { firstNameRef.current.innerHTML = 'Enter First Name'; clearmsg();}
        else if (!nameformat.test(data.first_name)) { firstNameRef.current.innerHTML = 'Enter Valid First Name'; clearmsg();}
        else if (data.last_name === "") { lastNameRef.current.innerHTML = 'Enter Last Name'; clearmsg();}
        else if (!nameformat.test(data.last_name)) { lastNameRef.current.innerHTML = 'Enter Valid Last Name'; clearmsg();}
        else if (data.ratings === null){ratingsRef.current.innerHTML = 'Give Your Ratings'; clearmsg();}
        else if (data.email === "") { emailRef.current.innerHTML = "Enter Email"; clearmsg(); }
        else if (!data.email.match(emailformat)) { emailRef.current.innerHTML = "Enter Valid Email"; clearmsg(); }
        else if (data.message === "") { MessageRef.current.innerHTML = "Write Message"; clearmsg(); }
        else {
          axios.post('/feedback', payload)
            .then((res) => {
              toast.success('Thank you for your feedback!...')
              console.log("feedback reached")
            })
            .catch((res) => {
              console.log("Error in feedback")
            })
        }
      }

      useEffect(() => {
        document.title = "Luxury Car Rentals in India | Blck Luxury | FeedBack";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            metaDescription.setAttribute("content", "At Blck Luxury, we prioritize your satisfaction and aim to provide the highest level of personalized service.");
        }
    }, []);

  return (<>
    
    < main className={s.feed_main}>
    <Toaster />
        <div className={s.feed_header}>
            <span>Feedback</span>
        </div>
        <div className={s.feed_divide}>
          <div className={`${s.personalInfoSection}`}>
            <span className={`${s.bigText}`}>Get in touch!</span>
            <span className={`${s.mediumText}`}>Let us know more about your feedback!</span>
            {/* <div className={`${s.infoForm}`}> */}
            <div className={`${s.split}`}>
              <input type="text" name='firstName' onChange={(e) => {
                setData({ ...data, first_name: e.target.value });
              }} placeholder='First Name' />
              <input type="text" name='lastname' onChange={(e) => {
                setData({ ...data, last_name: e.target.value });
              }} placeholder='Last Name' />
            </div>
            <div className={s.refdivision}>
              <div
                ref={firstNameRef}
                className={`${s.tinyText} ${s.secondaryColor}`}
              ></div>
              <div
                ref={lastNameRef}
                className={`${s.tinyText} ${s.secondaryColor}`}
              ></div>
            </div>
            <input type="text" name='email' onChange={(e) => {
              setData({ ...data, email: e.target.value });
            }} placeholder='Email' />
            <div
              ref={emailRef}
              className={`${s.tinyText} ${s.secondaryColor}`}
            ></div>
            <div className={s.rate}>
            <input type="radio" id="star5" name="rate" onChange={(e)=>{setData({ ...data, ratings: e.target.value });}} value="5" />
            <label for="star5" title="text">5 stars</label>
            <input type="radio" id="star4" name="rate" onChange={(e)=>{setData({ ...data, ratings: e.target.value });}} value="4" />
            <label for="star4" title="text">4 stars</label>
            <input type="radio" id="star3" name="rate" onChange={(e)=>{setData({ ...data, ratings: e.target.value });}} value="3" />
            <label for="star3" title="text">3 stars</label>
            <input type="radio" id="star2" name="rate" onChange={(e)=>{setData({ ...data, ratings: e.target.value });}} value="2" />
            <label for="star2" title="text">2 stars</label>
            <input type="radio" id="star1" name="rate" onChange={(e)=>{setData({ ...data, ratings: e.target.value });}} value="1" />
            <label for="star1" title="text">1 star</label>
          </div>
          <div
          ref={ratingsRef}
          className={`${s.tinyTextRating} ${s.secondaryColor}`}
        ></div>
            <textarea name="message" onChange={(e) => {
              setData({ ...data, message: e.target.value });
            }} cols="30" rows="5" placeholder='Your Message'></textarea>
            <div
              ref={MessageRef}
              className={`${s.tinyText} ${s.secondaryColor}`}
            ></div>
            <button className={`${c.primaryBtnFilled} ${s.selectDateBtn}`} onClick={handelFeedback}>Submit</button>
            {/* </div> */}
          </div>
          </div>
          
    </main>
    </>
  )
}

export default Feedback
