
import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import toast, { Toaster } from 'react-hot-toast';
import "react-toastify/dist/ReactToastify.css";
// import { TimePicker } from 'react-ios-time-picker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from '../../../helpers/Axios'
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import s from "./update.module.css"
import emailjs from "@emailjs/browser";
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  DropdownItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";
import {carname} from "./upcoming";
function BookingsModal({
  setShow,
  show,
  maildetails,
  id,
  carname,
  payment,
  type01,
  date01,
  time01,
  price01,
  agentname,
  location,
  email,
  status,
  alter_email,
  // alter_email2,
  phone,
  alt_phone,
}) {
  //   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 const [reson,setReason] =useState()
  const [reportingdate, setReportingdate] = useState(new Date());
  const [reportingtime, setReportingtime] = useState();
  const useCurrentCallback = (callback) => {
    const reference = React.useRef();
    reference.current = callback;
    return (...args) => {
      return reference.current?.(...args);
    };
  };

  const now = new Date();
  // console.log('Current date: ' + now.toDateString());
  // console.log('Current time: ' + now.toTimeString()); 
  const time = now.toLocaleTimeString();
  const date = now.toLocaleDateString();



  const [reciveData, setReceiveData] = useState([]);

  const showToastMessageUploadedSuccess = () => {
    toast.success("Updated Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const showToastMessageErroruploading = () => {
    toast.error("Error Updating !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

function sendEmail() {
  // e.preventDefault();-
  // alert("star")
  const  userdetails = JSON.parse(maildetails.user_details)
  const booking_summary = JSON.parse(maildetails.booking_summary);
  if(userdetails.package=='standard'){
    const data = {
      //user details
       user_email: userdetails.Email,
       to_name: userdetails.First_Name +""+userdetails.Last_Name,
       phonno:userdetails.Phone,
       Address:userdetails.Address,
       //car details
      car_name: booking_summary.name,
      car_price: userdetails.price,
      booking_startDate: booking_summary.startDate,
      booking_startTime: booking_summary.startTime,
      booking_id: maildetails.booking_id,
      payment_status:maildetails.payment_status,
      city: userdetails.location,
      reson:reson,
      Type:'standard'
    }
    console.log(data,"goooot")
    const service_id = "service_s5tk4mq";
    const template_id = "template_89kg51o";
    const user_id = "nPl0g9QOyS4S6H9Fy";
    emailjs.send(
      service_id,
      template_id,
      data,
      user_id
    ).then(
      (result) => {
        console.log(result.text);
        // console.log("message sent");
        toast.success('Cancellation / Modification Request Received...')
      },
      (error) => {
        console.log(error.text);
      }
    );
  }
  else if(userdetails.package=='Airport-pickup'){
    const data = {
      //user details
       user_email: userdetails.Email,
       to_name: userdetails.First_Name +""+userdetails.Last_Name,
       phonno:userdetails.Phone,
       Address:userdetails.Address,
       //car details
      car_name: booking_summary.name,
      car_price: userdetails.price,
      booking_startDate: booking_summary.startDate,
      booking_startTime: booking_summary.startTime,
      booking_id: maildetails.booking_id,
      payment_status:maildetails.payment_status,
      city: userdetails.location,
      reson:reson,
      Type:'Airport-pickup'
    }
    console.log(data,"goooot")
    const service_id = "service_s5tk4mq";
    const template_id = "template_89kg51o";
    const user_id = "nPl0g9QOyS4S6H9Fy";
    emailjs.send(
      service_id,
      template_id,
      data,
      user_id
    ).then(
      (result) => {
        console.log(result.text);
        // console.log("message sent");
        toast.success('Cancellation / Modification Request Received...')
      },
      (error) => {
        console.log(error.text);
      }
    );
  }
  else if(userdetails.package=='Outstation'){
    const data = {
      //user details
       user_email: userdetails.Email,
       to_name: userdetails.First_Name +""+userdetails.Last_Name,
       phonno:userdetails.Phone,
       Address:userdetails.Address,
       //car details
      car_name: booking_summary.name,
      car_price: userdetails.price,
      booking_startDate: booking_summary.startDate,
      booking_startTime: booking_summary.startTime,
      booking_endDate:booking_summary.endDate,
      booking_endtime:booking_summary.endTime,
      booking_id: maildetails.booking_id,
      payment_status:maildetails.payment_status,
      city: userdetails.location,
      reson:reson,
      Type:'Outstation'
    }
    console.log(data,"goooot")
    const service_id = "service_s5tk4mq";
    const template_id = "template_89kg51o";
    const user_id = "nPl0g9QOyS4S6H9Fy";
    emailjs.send(
      service_id,
      template_id,
      data,
      user_id
    ).then(
      (result) => {
        console.log(result.text);
        // console.log("message sent");
        toast.success('Cancellation / Modification Request Received...')
      },
      (error) => {
        console.log(error.text);
      }
    );
  }
  else if(userdetails.package=='Self-drive'){
    const data = {
      //user details
       user_email: userdetails.Email,
       to_name: userdetails.First_Name +""+userdetails.Last_Name,
       phonno:userdetails.Phone,
       Address:userdetails.Address,
       //car details
      car_name: booking_summary.name,
      car_price: userdetails.price,
      booking_startDate: booking_summary.startDate,
      booking_startTime: booking_summary.startTime,
      booking_endDate:booking_summary.endDate,
      booking_endtime:booking_summary.endTime,
      booking_id: maildetails.booking_id,
      payment_status:maildetails.payment_status,
      city: userdetails.location,
      reson:reson,
      Type:'Self-drive'
    }
    console.log(data,"goooot")
    const service_id = "service_s5tk4mq";
    const template_id = "template_89kg51o";
    const user_id = "nPl0g9QOyS4S6H9Fy";
    emailjs.send(
      service_id,
      template_id,
      data,
      user_id
    ).then(
      (result) => {
        console.log(result.text);
        // console.log("message sent");
        toast.success('Cancellation / Modification Request Received...')
      },
      (error) => {
        console.log(error.text);
      }
    );
  }
  



  // axios.get(`https://api.mylogin.co.in/api/v2/SendSMS?ApiKey=8NzlHH40L/AH3MR83XpYmT5d1EQVofsbB+Vx1jxqUcY=&ClientId=e649ffb9-407e-49ec-8155-8eba78e30620&SenderId=BLKLUX&Message=Customer OTP verfication for Blck luxury!, Your otp is\n ${q} Happy driving with Blck Luxury!.&MobileNumbers=${8675036284}`)
   
  toast.success('Sms send...')
}
function sendEmail() {
  // e.preventDefault();-
  // alert("star")
  const  userdetails = JSON.parse(maildetails.user_details)
  const booking_summary = JSON.parse(maildetails.booking_summary);
  if(userdetails.Car_Type=='Standard'){
    const data = {
      //user details
       user_email: userdetails.Email,
       to_name: userdetails.First_Name +""+userdetails.Last_Name,
       phonno:userdetails.Phone,
       Address:userdetails.Address,
       //car details
      car_name: booking_summary.name,
      car_price: userdetails.price,
      booking_startDate: booking_summary.startDate,
      booking_startTime: booking_summary.startTime,
      booking_id: maildetails.booking_id,
      payment_status:maildetails.payment_status,
      city: userdetails.location,
      reson:reson,
      Type:'Standard'
    }
    console.log(data,"goooot")
    const service_id = "service_s5tk4mq";
    const template_id = "template_89kg51o";
    const user_id = "nPl0g9QOyS4S6H9Fy";
    emailjs.send(
      service_id,
      template_id,
      data,
      user_id
    ).then(
      (result) => {
        console.log(result.text);
        console.log("message sent");
      },
      (error) => {
        console.log(error.text);
      }
    );
  }
  else if(userdetails.Car_Type=='Airport-pickup'){
    const data = {
      //user details
       user_email: userdetails.Email,
       to_name: userdetails.First_Name +""+userdetails.Last_Name,
       phonno:userdetails.Phone,
       Address:userdetails.Address,
       //car details
      car_name: booking_summary.name,
      car_price: userdetails.price,
      booking_startDate: booking_summary.startDate,
      booking_startTime: booking_summary.startTime,
      booking_id: maildetails.booking_id,
      payment_status:maildetails.payment_status,
      city: userdetails.location,
      reson:reson,
      Type:'Airport-pickup'
    }
    console.log(data,"goooot")
    const service_id = "service_s5tk4mq";
    const template_id = "template_89kg51o";
    const user_id = "nPl0g9QOyS4S6H9Fy";
    emailjs.send(
      service_id,
      template_id,
      data,
      user_id
    ).then(
      (result) => {
        console.log(result.text);
        console.log("message sent");
      },
      (error) => {
        console.log(error.text);
      }
    );
  }
  else if(userdetails.Car_Type='Outstation'){
    const data = {
      //user details
       user_email: userdetails.Email,
       to_name: userdetails.First_Name +""+userdetails.Last_Name,
       phonno:userdetails.Phone,
       Address:userdetails.Address,
       //car details
      car_name: booking_summary.name,
      car_price: userdetails.price,
      booking_startDate: booking_summary.startDate,
      booking_startTime: booking_summary.startTime,
      booking_endDate:booking_summary.endDate,
      booking_endtime:booking_summary.endTime,
      booking_id: maildetails.booking_id,
      payment_status:maildetails.payment_status,
      city: userdetails.location,
      reson:reson,
      Type:'Outstation'
    }
    console.log(data,"goooot")
    const service_id = "service_s5tk4mq";
    const template_id = "template_89kg51o";
    const user_id = "nPl0g9QOyS4S6H9Fy";
    emailjs.send(
      service_id,
      template_id,
      data,
      user_id
    ).then(
      (result) => {
        console.log(result.text);
        console.log("message sent");
      },
      (error) => {
        console.log(error.text);
      }
    );
  }
  else if(userdetails.Car_Type=='SelfDrive'){
    const data = {
      //user details
       user_email: userdetails.Email,
       to_name: userdetails.First_Name +""+userdetails.Last_Name,
       phonno:userdetails.Phone,
       Address:userdetails.Address,
       //car details
      car_name: booking_summary.name,
      car_price: userdetails.price,
      booking_startDate: booking_summary.startDate,
      booking_startTime: booking_summary.startTime,
      booking_endDate:booking_summary.endDate,
      booking_endtime:booking_summary.endTime,
      booking_id: maildetails.booking_id,
      payment_status:maildetails.payment_status,
      city: userdetails.location,
      reson:reson,
      Type:'SelfDrive'
    }
    console.log(data,"goooot")
    const service_id = "service_s5tk4mq";
    const template_id = "template_89kg51o";
    const user_id = "nPl0g9QOyS4S6H9Fy";
    emailjs.send(
      service_id,
      template_id,
      data,
      user_id
    ).then(
      (result) => {
        console.log(result.text);
        console.log("message sent");
      },
      (error) => {
        console.log(error.text);
      }
    );
  }
  
};
// console.log(reson,reciveData,reportingtime,"yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy")
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className={s.modalheader}>
          {/* <Modal.Title>Greetings from BLCK!
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body className={s.modelbody}>
          <Form className={s.modalcontent}>
            <Form.Group
              className={`mb-3 ${s.formContainer}`}
              controlId="exampleForm.ControlInput1"
            >
              <div className={`auth-page-content ${s.formBackground}`}>
                <Container fluid className={s.conentcontainer}>
                 
                </Container>
                <p className={s.title}>Please give us  a reason for your modification in your reservation</p>
                <Input className={s.notecontent} type="textarea"  onChange={e=>setReason(e.target.value)}></Input>
                <ToastContainer/>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className={s.modalfooter}>
          <Button
            variant="success"
            className={s.sendbutton}
            onClick={sendEmail}
            //   let payload = {
            //     agentname: updatedagentname,
            //     id: id,
            //     location: updatedlocation,
            //     email: updatedemail,
            //     alter_email: updatedaltemail,
            //     // alter_email2: updatedaltemai2,
            //     phone: updatedphn,
            //     status: updatedstatus,
            //     alt_phone: updatedaltphn,
            //   };
            //   axios
            //     .put("/updateAgentslist", payload)
            //     .then((res) => {
            //       // setIsOpen(false)
            //       showToastMessageUploadedSuccess();
            //     })
            //     .catch((err) => {
            //       // setIsOpen(false)
            //       showToastMessageErroruploading();
            //     });
            // }}
          >
            Send
          </Button>

        {/*  <Button variant="secondary" onClick={handleClose}>
            Close
                              </Button>*/}
        </Modal.Footer>
      </Modal>
      <Toaster />
    </>
  );
}

// render(<ModalView />);
export default BookingsModal;
