import React, { useContext, useState } from 'react';
import s from './loginpage.module.css';
import c from '../../assets/css/custom.module.css';
import OtpInput from 'otp-input-react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { ContextLogin, UserId, PrevUrl } from '../../helpers/context';
import axios from '../../helpers/Axios';
import ReCAPTCHA from 'react-google-recaptcha';

function LoginPage() {
  const [isLogged, setIsLogged] = useContext(ContextLogin);
  const [userId, setUserId] = useContext(UserId);
  const [prevUrl, setPrevUrl] = useContext(PrevUrl);

  const [showOtp, setShowOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [contact, setContact] = useState("");
  const [randomOTP, setRandomOTP] = useState("");
  const [data, setData] = useState({ phone: "", email: "", firstname: "", lastname: "", id: "" });
  const [capVal, setCapVal] = useState(null); // ReCAPTCHA value state
  const [showCaptcha, setShowCaptcha] = useState(false); // State to show/hide captcha

  let nav = useNavigate();

  async function otpauth() {
    if (!contact) {
      toast.error("Please enter your phone number.");
      return;
    }

    axios.get(`/user_information?contact=${contact}`).then((res) => {
      const userData = res.data.user[0];
      setData({
        phone: userData.contact,
        email: userData.email,
        firstname: userData.firstname,
        lastname: userData.lastname,
        id: userData.id
      });
    });

    function generateOTP() {
      const digits = '0123456789';
      let otp = '';
      for (let i = 0; i < 6; i++) {
        otp += digits[Math.floor(Math.random() * 10)];
      }
      return otp;
    }

    const randomOTP = generateOTP();
    if (contact) {
      setShowOtp(true); // Show OTP input field
      setRandomOTP(randomOTP);
      axios.get(`https://api.mylogin.co.in/api/v2/SendSMS?ApiKey=8NzlHH40L/AH3MR83XpYmT5d1EQVofsbB+Vx1jxqUcY=&ClientId=e649ffb9-407e-49ec-8155-8eba78e30620&SenderId=BLKLUX&Message=Thank you for choosing Blck Luxury. Your OTP for login ${randomOTP}. We are excited to have you on board!.&MobileNumbers=91${contact}&Is_Unicode=Is_Unicode=false&Is_Flash=false`);

      toast.success('SMS sent...');
    }
  }

  function otpEntered(enteredOtp) {
    setOtp(enteredOtp);
    if (enteredOtp.length === 6) {
      setShowCaptcha(true); // Show CAPTCHA only after OTP is fully entered
    } else {
      setShowCaptcha(false); // Hide CAPTCHA if OTP is incomplete
    }
  }

  function otpcheck() {
    if (!otp) {
      toast.error("Please enter OTP.");
      return;
    }

    if (!capVal) {
      toast.error("Please complete the CAPTCHA.");
      return;
    }

    if (randomOTP === otp) {
      if (data.id) {
        localStorage["USER_ID"] = data.id;
        localStorage["USER_firstname"] = data.firstname;
        localStorage["USER_email"] = data.email;
        localStorage["USER_lastname"] = data.lastname;
        localStorage["USER_PHONE_NO"] = data.phone;
        toast.success('Login Successful');
        let webpage = sessionStorage.getItem("url1");
        if (webpage) {
          window.open(webpage);
        } else {
          nav('/navbar', { userdetails: data });
        }
      } else {
        toast.error("Please Create Account");
      }
    } else {
      toast.error("OTP is incorrect");
    }
  }

  return (
    <div className={`${s.logingSection}`}>
      <Toaster />
      <main className={`${s.container}`}>
        <div className={`${c.bigText} ${c.bold}`}>Login to your account</div>
        <div className={`${s.phoneInput}`}>
          <span className={`${c.smallText}`}>Enter mobile number</span>
          <div className={`${s.inputs}`}>
            <input className={`${s.input1}`} value={'+91'} readOnly />
            <input value={contact} onChange={(e) => setContact(e.target.value)} className={`${s.input2}`} type="text" name="phone" placeholder='Enter Mobile Number' />
          </div>
        </div>
        <div className={`${s.bottomSection}`}>
          <button onClick={otpauth} className={`${c.primaryBtnFilled}`}>Send OTP</button>
          {showOtp && (
            <>
              <div className={`${s.otpinputs}`}>
                <OtpInput value={otp} onChange={otpEntered} OTPLength={6} otpType="number" />
              </div>
              {showCaptcha && (
                <div className={`${s.captchaContainer}`}> {/* Center-aligned container */}
                <ReCAPTCHA
                  sitekey="6Lcb5TQqAAAAADzVhqAiIRa26IESuiKvH8aQpx77"
                  onChange={(val) => setCapVal(val)}
                />
              </div>
              )}
              <button onClick={otpcheck} className={`${c.primaryBtnFilled}`} disabled={!capVal}>Login</button>
            </>
          )}
          <div className={`${c.smallText} ${c.center}`} onClick={() => { nav('/signup') }} style={{ cursor: 'pointer' }}><u>No account? Create one</u></div>
        </div>
      </main>
    </div>
  );
}

export default LoginPage;
