import React, { useState } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import "./faq.css";

export default function Question({ title, info }) {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <article className="question">
      <header className="question-header">
        <h6>{title}</h6>
        <button className="btn" onClick={() => setShowDetails(!showDetails)}>
          {showDetails ? <AiOutlineMinus /> : <AiOutlinePlus />}
        </button>
      </header>
      <div className={`content-container ${showDetails ? "open" : ""}`}>
        <p>{info}</p>
      </div>
    </article>
  );
}
