import React, { useState } from 'react';
import axios from '../../helpers/Axios';
import s from './EmailSubscriptionForm.module.css';

function EmailSubscriptionForm() {
  const [email, setEmail] = useState('');
  const [subscribed, setSubscribed] = useState(false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const Subscription = () => {
    const payload = {
      email: email,
    };

    axios
      .post('/viewers', payload)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    Subscription(); // Call the Subscription function to make the API call
    setTimeout(() => {
      setSubscribed(true);
    }, 1000);
  };

  return (
    <div>
      {subscribed ? (
        <p>Thank you for subscribing!</p>
      ) : (
        <form onSubmit={handleSubmit} className={s.subicon}>
          <input
            type="email"
            value={email}
            onChange={handleEmailChange}
            placeholder="Enter your email"
            required
            className={s.inputField}
          />
          <button type="submit" className={s.subscribeButton}>
            Subscribe
          </button>
        </form>
      )}
    </div>
  );
}

export default EmailSubscriptionForm;
