import React from 'react'
import s from './partnerships.module.css'
import partnershipImg from '../../../assets/images/partnership.jpg'

const Partnerships = () => {
  return (
    <main className={s.partnership_main}>
      <div className={s.partnerships_img_and_desc}>
        <img src={partnershipImg}/>
        <div className={s.partnership_desc}>
          <p>All things Luxury now made easier.</p>
          <p>BLCK ties up with 221 Luxury network for all things luxury. Be it Cars, Yachts, Jets, Real estate, Fine art or a bottle of oldest and rarest whiskey in existence and the world’s first Fabergé Celtic Egg.</p>
          <p>This is for all those who eludes finesse and crave everything luxurious.</p>
          <p>For more details contact <a>contact@goblck.in</a></p>
        </div>
      </div>
    </main>
  )
}

export default Partnerships
