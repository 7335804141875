import React from 'react'
import s from '../details/details.module.css'
import c from '../../assets/css/custom.module.css'
import { Link } from 'react-router-dom'
function PleaseReadSection() {
    return (
        <>
            <div className={`${s.descHeader} ${c.headerText} ${c.prefixed}`}>
                Please Read
            </div>
            <ol className={`${c.prefixed} ${c.paragraphText}`}>
                <li>The Distance and Time are calculated from garage to garage.</li>
                <li>Tolls, taxes and parking fees are as per actuals & additional to the above prices, payable by customers at the end of the trip.</li>
                <li>If the selected car model is unavailable a similar car shall be sent over.</li>
                <li>Display images are just for reference, colour, variant and features may vary.</li>
                <li>Any extra charges over and above the package limit need to be paid at the end of the trip. </li>
                <li>Refund & Cancellation as per the policy. </li>
            </ol>
            <Link to="/terms-and-conditions"><p className={`${c.btn_read_more} ${c.headerText}`}>Read More...</p></Link>
        </>
    )
}

export default PleaseReadSection