import React, { useState, useRef, useEffect} from 'react'
import s from './contactus.module.css'
import c from '../../assets/css/custom.module.css'
import axios from '../../helpers/Axios'
import { colors } from '@mui/material';
import toast, { Toaster } from 'react-hot-toast';
import emailjs from "@emailjs/browser";

function ContactUs() {

  // localStorage.clear();
  useEffect(() => {
    document.title = "Luxury Car Rentals in India | Blck Luxury | Contact Us";
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
        metaDescription.setAttribute("content", "At Blck Luxury, we prioritize your satisfaction and aim to provide the highest level of personalized service.");
    }
}, []);

  const [data, setData] = useState(
    {
      user_name: "",
      email: "",
      phone_number: "",
      looking_for: "",
      city: "",
      message: "",
    }
  )

  const userNameRef = useRef();
  const phoneNumberRef = useRef();
  const emailRef = useRef();
  const lookingforRef = useRef();
  const cityRef = useRef();
  const MessageRef = useRef();


  const emailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const nameformat = /^[A-Za-z" "_.]+$/;
  const phoneNumPattern = /^\d{10}$/;
  const numberformat = /^[0-9]+$/;


  function clearmsg() {
    setTimeout(() => {
      userNameRef.current.innerHTML = "";
      emailRef.current.innerHTML = "";
      phoneNumberRef.current.innerHTML = "";
      lookingforRef.current.innerHTML = "";
      cityRef.current.innerHTML = "";
      MessageRef.current.innerHTML = "";
    }, 4000);
  }

  const payload = {
    ...data,
  };

  function HandelEquiryMSG() {
    if (data.user_name === "") { userNameRef.current.innerHTML = 'Enter Name'; clearmsg();}
    else if (!nameformat.test(data.user_name)) { userNameRef.current.innerHTML = 'Enter Valid Name'; clearmsg();}
    else if (data.phone_number === "") { phoneNumberRef.current.innerHTML = "Enter Phone Number"; clearmsg(); }
    else if (!phoneNumPattern.test(data.phone_number)) {phoneNumberRef.current.innerHTML = "Enter Valid Phone Number"; clearmsg();}
    else if (data.email === "") { emailRef.current.innerHTML = "Enter Email"; clearmsg(); }
    else if (!data.email.match(emailformat)) { emailRef.current.innerHTML = "Enter Valid Email"; clearmsg(); }
    else if (data.looking_for === "") { lookingforRef.current.innerHTML = "Select Looking For Options"; clearmsg(); }
    else if (data.city === "") { cityRef.current.innerHTML = "Enter City"; clearmsg(); }
    else if (data.message === "") { MessageRef.current.innerHTML = "Write Message"; clearmsg(); }
    else {
      axios.post('/general_enquires', payload)
        .then((res) => {
          toast.success('Thank you for contacting us!...')
          console.log("general_eneuires reached")
          sendEmail()
        })
        .catch((res) => {
          console.log(res,"Error in general enquires")
        })


    }
  }
  function sendEmail() {
    // e.preventDefault();-
    console.log(data)
    const sms_data = {
      user_email: data.email,
      to_name: data.user_name,
      user_number:data.phone_number,
      city:data.city,
      looking_for: data.looking_for,
      message: data.message,
      
    }
  
    
    const service_id = "service_kg7yi3c";
    const template_id = "template_a2nx11s";
    const user_id = "VCFkUIdrxyggthL1g";
    emailjs.send(
      service_id,
      template_id,
      sms_data,
      user_id
    )
      .then(
        (result) => {
          // console.log(result.text);
          console.log("email send");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <main className={s.contactus}>
    <Toaster />
      <div className={s.contactusElements}>
        <div className={s.banner}>
          {/*<span>Contact Us</span>*/}
        </div>
        <div className={s.divide}>
          <div className={`${s.personalInfoSection}`}>
            <span className={`${s.bigText}`}>Get in touch!</span>
            <span className={`${s.mediumText}`}>Let us know more about you!</span>
            {/* <div className={`${s.infoForm}`}> */}
            <div className={`${s.split}`}>
              <input type="text" name='firstName' onChange={(e) => {
                setData({ ...data, user_name: e.target.value });
              }} placeholder='Full Name' />
              <input type="text" name='mobileNumber' onChange={(e) => {
                setData({ ...data, phone_number: e.target.value });
              }} placeholder='Mobile Number' />
            </div>
            <div className={s.refdivision}>
              <div
                ref={userNameRef}
                className={`${s.tinyText} ${s.secondaryColor}`}
              ></div>
              <div
                ref={phoneNumberRef}
                className={`${s.tinyText} ${s.secondaryColor}`}
              ></div>
            </div>
            <input type="text" name='email' onChange={(e) => {
              setData({ ...data, email: e.target.value });
            }} placeholder='Email' />
            <div
              ref={emailRef}
              className={`${s.tinyText} ${s.secondaryColor}`}
            ></div>
            <div className={`${s.split_division}`}>
              <select onChange={(e) => {
                setData({ ...data, looking_for: e.target.value });
              }}>
                <option>Looking For</option>
                <option value="Car">Cars</option>
                <option value="Yacht">Yacht</option>
                <option value="Jet">Jets</option>
              </select>
              <input type="text" name='city' onChange={(e) => {
                setData({ ...data, city: e.target.value });
              }} placeholder='City' />
            </div>
            <div className={s.refdivision}>
              <div
                ref={lookingforRef}
                className={`${s.tinyText} ${s.secondaryColor}`}
              ></div>
              <div
                ref={cityRef}
                className={`${s.tinyText} ${s.secondaryColor}`}
              ></div>
            </div>
            <textarea name="message" onChange={(e) => {
              setData({ ...data, message: e.target.value });
            }} cols="30" rows="3" placeholder='Your Message'></textarea>
            <div
              ref={MessageRef}
              className={`${s.tinyText} ${s.secondaryColor}`}
            ></div>
            <button className={`${c.primaryBtnFilled} ${s.selectDateBtn}`} onClick={HandelEquiryMSG}>Send Message</button>
            {/* </div> */}
          </div>
          <div className={s.rightSide}>
            <span className={`${s.bigText}`}>Contact Information</span>
            <div className={s.cInfo}>
              <div className={s.phone}>
                <span>Phone: </span>
                {/* <p><a href="tel:+919900085611">+91 9900085611</a></p> */}
                <p style={{ color: 'Grey' }}>+91 9900085611</p> 
              </div>
              <div className={s.email}>
                <span>E-mail: </span>
                {/* <p><a href="mailto:info@blck.luxury">info@blck.luxury</a></p> */}
                <p style={{ color: 'Grey' }}>Contact@goblck.in</p>
              </div>
              <div className={s.address}>
                <span>Corporate Office</span>
                <p>WeWork Pavilion, 62/63 The Pavilion, Church Street, Bangalore, Karnataka 560001</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default ContactUs