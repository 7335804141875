
// import React,{useEffect,useState} from "react";
// import logo from "../../assets/images/KIA.jpg";
// import { useLocation, useNavigate } from "react-router";
// import axios from "../../helpers/Axios";
// import { api_url, aws_bucket_url, json_server_url } from '../../helpers/base_url'


// function Payments() {

//     let location = useLocation()
//     let navigate = useNavigate()
//     const [state, setState] = useState({});
//     useEffect(() => {
//         console.log(location.state,"state")
//         setState(location.state)
//       }, []);
//     function loadScript(src) {
//         return new Promise((resolve) => {
//             const script = document.createElement("script");
//             script.src = src;
//             script.onload = () => {
//                 resolve(true);
//             };
//             script.onerror = () => {
//                 resolve(false);
//             };
//             document.body.appendChild(script);
//         });
//     }

//     async function displayRazorpay(state) {

//         const res = await loadScript(
//             "https://checkout.razorpay.com/v1/checkout.js"
//         );

//         if (!res) {
//             alert("Razorpay SDK failed to load. Are you online?");
//             return;
//         }

//         // creating a new order
//         const result = await axios.post(`${api_url}/create_order`,state);
//         console.log(result)
//         if (!result) {
//             alert("Server error. Are you online?");
//             return;
//         }

//         // Getting the order details back
//         const { amount, id: order_id, currency } = result.data;

//         const options = {
//             key: "rzp_test_nCxyGM01bTExAT", // Enter the Key ID generated from the Dashboard
//             amount: amount.toString(),
//             currency: currency,
//             name: "Soumya Corp.",
//             description: "Test Transaction",
//             image: { logo },
//             order_id: order_id,
//             handler: async function (response) {

//                 const data = {
//                     orderCreationId: order_id,
//                     razorpayPaymentId: response.razorpay_payment_id,
//                     razorpayOrderId: response.razorpay_order_id,
//                     razorpaySignature: response.razorpay_signature,
//                 };
//                 console.log(data,"data from handler")
//                if(order_id) navigate('/')
//                 const result = await axios.post("http://localhost:5000/payment/success", data);

//                 alert(result.data.msg);
//             },
//             prefill: {
//                 name: "Soumya Dey",
//                 email: "SoumyaDey@example.com",
//                 contact: "9999999999",
//             },
//             notes: {
//                 address: "Soumya Dey Corporate Office",
//             },
//             theme: {
//                 color: "#61dafb",
//             },
//         };

//         const paymentObject = new window.Razorpay(options);
//         paymentObject.open();
//     }
//     return (
//         <div className="App">
//             <header className="App-header">
//                 <img src={logo} className="App-logo" alt="logo" />
//                 <p>Pay Now</p>
//                 <button className="App-link" onClick={() => displayRazorpay(state)}>
//                     Pay ₹{state.price}
//                 </button>
//             </header>
//         </div>
//     );
// }

// export default Payments;


import React, { useEffect, useState } from "react";
import logo from "../../assets/images/KIA.jpg";
import { useLocation, useNavigate } from "react-router";
import axios from "axios";
import { api_url, aws_bucket_url, json_server_url } from '../../helpers/base_url'
import "./payment.css"


function Payments() {

    let location = useLocation()
    let navigate = useNavigate()
    const [state, setState] = useState({});
    const [fName, setfName] = useState('');
    const [lName, setlName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');

    const submitValue = () => {
        const frmdetails = {
            'First Name': fName,
            'Last Name': lName,
            'Phone': phone,
            'Email': email,
            "Address": address
        }
        console.log(frmdetails);
    }

    useEffect(() => {
        console.log(location.state, "state")
        setState(location.state)
    }, []);
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay(state) {
        const frmdetails = {
            'First Name': fName,
            'Last Name': lName,
            'Phone': phone,
            'Email': email,
            "Address": address
        }
        if (frmdetails.Email === "" && frmdetails.Phone === "" && frmdetails.Address === "") {
            alert("Please enter the Customer Details! ")
        }
        else {
            console.log(frmdetails)
            const res = await loadScript(
                "https://checkout.razorpay.com/v1/checkout.js"
            );

            if (!res) {
                alert("Razorpay SDK failed to load. Are you online?");
                return;
            }

            // creating a new order
            const result = await axios.post(`${api_url}/create_order`,state);
            // const result = await axios.post(`http://localhost:5001/create_order`, state);


            console.log(result)
            if (!result) {
                alert("Server error. Are you online?");
                return;
            }

            // Getting the order details back
            const { amount, id: order_id, currency } = result.data;

            const options = {
                // key: "rzp_test_nCxyGM01bTExAT", // Enter the Key ID generated from the Dashboard
                key: "rzp_live_1P9Ft95ijJAFfb",
                amount: amount.toString(),
                currency: currency,
                name: frmdetails["First Name"] + '' + frmdetails["Last Name"],
                description: "payment",
                image: { logo },
                order_id: order_id,
                handler: async function (response) {

                    const data = {
                        orderCreationId: order_id,
                        razorpayPaymentId: response.razorpay_payment_id,
                        razorpayOrderId: response.razorpay_order_id,
                        razorpaySignature: response.razorpay_signature,
                    };
                    console.log(data, "data from handler")
                    if (order_id) {
                        let data_post =
                        {
                            user_id: frmdetails.Phone,
                            booking_id: frmdetails.Phone + '-' + data.razorpayOrderId,
                            user_details: JSON.stringify(frmdetails),
                            razor_orderId: data.razorpayOrderId,
                            razor_sign: data.razorpaySignature,
                            booking_summary: JSON.stringify(state),
                            razor_paymentId: data.razorpayPaymentId,
                            payment_status: "Success",
                        }
                        console.log(data_post,"datapost")
                    //    const result = await axios.post("http://localhost:5001/payment/success", data_post);
                       const result = await axios.post(`${api_url}/payment/success`, data_post);
                        
                       navigate('/')

                    }

                    alert(result.data.msg);
                },
                prefill: {
                    name: frmdetails["First Name"] + '' + frmdetails["Last Name"],
                    email: frmdetails["Email"],
                    contact: frmdetails["Phone"],
                },
                notes: {
                    address: frmdetails["Address"],
                },
                theme: {
                    color: "#61dafb",
                },
            };

            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
        }

    }
    return (
        <div className="payment-div">
            <header className="payment-header">
                <div className="customer-details-form">
                    <img src={logo} className="App-logo mb-4" alt="logo" />
                    <input type="text" className="form-control mt-1" placeholder="First Name" onChange={e => setfName(e.target.value)} />
                    <input type="text" className="form-control mt-1" placeholder="Last Name" onChange={e => setlName(e.target.value)} />
                    <input type="text" className="form-control mt-1" placeholder="Phone" onChange={e => setPhone(e.target.value)} />
                    <input type="text" className="form-control mt-1" placeholder="Email" onChange={e => setEmail(e.target.value)} />
                    <input type="text" className="form-control mt-1" placeholder="Address" onChange={e => setAddress(e.target.value)} />
                    <button className="form-control mt-3 btn btn-danger" onClick={() => displayRazorpay(state)}>
                        Pay  ₹{state.price}
                    </button>
                </div>

            </header>
        </div>
    );
}

export default Payments;
