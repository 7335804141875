import React, { useEffect, useState } from 'react'
import s from './signup.module.css'
import c from '../../assets/css/custom.module.css'
// import axios from "../../helpers/Axios";
import axios from "../../helpers/Axios";
import OtpInput from "otp-input-react";
import toast, { Toaster } from 'react-hot-toast';
// import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useContext } from 'react'
import { api_url1, json_server_url } from '../../helpers/base_url'
function CreateAccount() {
  let nav = useNavigate()
  const[firstname,setFristname]=useState("")
  const[lastname,setLastname]=useState("")
  const[email,setEmail]=useState("")
  const[contact,setContact]=useState("")
  const [showotp, setShowotp] = useState(false)
  const [otp, setOtp] = useState("");
  const [randomOTP, setRandomOTP] = useState("");
async function otpauth(){
 
  function generateOTP() {
    const digits = '0123456789';
    let otp = '';
    for (let i = 0; i < 6; i++) {
      otp += digits[Math.floor(Math.random() * 10)];
    }
    return otp;
  }
  
  // Example usage
  const randomOTP = generateOTP();
  if(firstname ==""){
    toast.error("Please enter firstname")
  }
  else if(lastname =="")
  { toast.error("Please enter lastname")}
  else if(email =="")
  { toast.error("Please enter email")}
  else if(contact =="")
  { toast.error("Please enter contact")}
  
else if(firstname,lastname,email,contact){ setShowotp(!showotp)}
    setRandomOTP(randomOTP)
    // console.log(contact,'########');
    // console.log(randomOTP,'########');
    // console.log(otp,'########');
  
   
    //otp send with api
    //  axios.get(`https://api.mylogin.co.in/api/v2/SendSMS?ApiKey=8NzlHH40L/AH3MR83XpYmT5d1EQVofsbB+Vx1jxqUcY=&ClientId=e649ffb9-407e-49ec-8155-8eba78e30620&SenderId=BLKLUX&Message=Customer OTP verfication for Blck luxury!, Your otp is ${randomOTP} Happy driving with Blck Luxury!.&MobileNumbers=91${contact}`)
    axios.get(`https://api.mylogin.co.in/api/v2/SendSMS?ApiKey=8NzlHH40L/AH3MR83XpYmT5d1EQVofsbB+Vx1jxqUcY=&ClientId=e649ffb9-407e-49ec-8155-8eba78e30620&SenderId=BLKLUX&Message=Thank you for choosing Blck Luxury. Your OTP for login ${randomOTP}. We are excited to have you on board!.&MobileNumbers=91${contact}&Is_Unicode=Is_Unicode=false&Is_Flash=false`)

   
    toast.success('Sms send...')
   
 
  
  }
function otpcheck(){

// 
// console.log(randomOTP,'########');
// console.log(otp,'########');
if(otp){
if (randomOTP==otp){
// console.log('done')
  let payload = { firstname, lastname, email, contact };
  axios
    .post('/user_information', payload)
    .then((res) => {
      console.log('create-account-details stored');
      // toast.success('Account has been created',);
      nav(`/login`)
    })
    .catch(() => {
      toast.error("Account details already exists")
      // console.log('Account details already exists');
     
    });
}
else{
  toast.error("OTP is wrong")
}
}
else{ toast.error("Plese enter OTP")}

  }

  
  useEffect(() => {
  async function gettingData() {
  await axios.get('/user_information')
  .then((res) => {
    // console.log('done')
  })
  }
    gettingData();
  }, [])
  return (
    <div className={`${s.signupSection}`}>
       <Toaster />
    <main className={`${s.container}`}>
      <div className={`${c.bigText} ${c.bold}`}>Create a New Account</div>
      <div className={`${s.detailsInput}`}>
        <span className={`${c.smallText}`}>Enter your details</span>
        <div className={`${s.inputs}`}>
          <div className={`${s.userDetails}`}>
            {/* <input className={`${s.usernameInput}`} type="text" placeholder='Username' /> */}
            <div className={`${s.splitInputs}`}>
              <input onChange={(e)=>{setFristname(e.target.value)}} type="text" placeholder='First Name' />
              <input onChange={(e)=>{setLastname(e.target.value)}} type="text" placeholder='Last Name' />
            </div>
            {/* <div className={`${s.splitInputs}`}>
              <input type="text" placeholder='Password' />
              <input type="text" placeholder='Confirm Password' />
            </div> */}
             {/* onChange={(e) => {
                  setData({ ...data, user_name: e.target.value });
                }} */}
            <input onChange={(e)=>{setEmail(e.target.value)}}  type="text" placeholder='Email' />
          </div>
          <div className={`${s.phone}`}>
            <input className={`${s.countryCode}`} type="text" name="countryCode" value={'+91'} />
            <input onChange={(e)=>{setContact(e.target.value)}} className={`${s.phoneInput}`} type="text" name="phone" placeholder='Mobile Number' />
          </div>
        </div>
      </div>
      <div className={`${s.bottomSection}`}>
        <div className={`${c.extraSmallText}`}>By continuing I accept the <span className={`${c.secondaryColor} ${c.bolder}`}>Terms & Conditions</span></div>
        <button onClick={otpauth} className={`${c.primaryBtnFilled}`}>Send OTP</button>
        {
            showotp ?
              <div className={`${s.otpinputs}`}>
                <OtpInput defaultValue={""} value={otp} onChange={setOtp} type="text" name="otp" OTPLength={6} otpType="number" />
              </div>
              : <></>
          }
           <button onClick={otpcheck} className={`${c.primaryBtnFilled}`}>Log in</button>
        <div className={`${c.extraSmallText} ${c.end}`} style={{cursor:'pointer'}} onClick={()=>{nav('/login')}}><u>Already have an Account?</u></div>
      </div>
    </main>
  </div>
  )
}

export default CreateAccount