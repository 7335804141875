const questions = [
    {
      id: 1,
      title: "Can you rent Luxury cars in India?",
      info: "Yes, you can rent luxury cars in India. Several luxury car rental companies offer a wide selection of luxury cars, including brands like Mercedes, BMW, Audi, and Porsche.",
    },
    {
      id: 2,
      title: "Where can I get a luxury car rental service in India?",
      info: "Luxury car rental services are available in major cities across India, including Delhi, Mumbai, Bangalore, Chennai, Hyderabad, Goa, and Jaipur. Companies like Blck Luxury and other leading providers offer a wide range of luxury cars for rent.",
    },
    {
      id: 3,
      title: "How much will it cost to hire a luxury car for a wedding/business?",
      info: "The cost to hire a luxury car for a wedding or business can vary widely depending on the vehicle model and rental duration. Prices typically range from ₹10,000 to ₹1,50,000 per day.",
    },
    {
      id: 4,
      title: "Where to rent a car with a chauffeur-driven in India?",
      info: "You can rent a car with a driver in India from various luxury car rental services. Companies like Blck Luxury offer chauffeur-driven luxury cars across major cities in India.",
    },
    {
      id: 5,
      title: "What luxury cars are available for rent?",
      info: "At Blck Luxury Car Rental, we offer a wide selection of premium cars like Toyota Velfire, Kia Carnival, Toyota Fortuner, and Toyota Crysta. Our fleet includes sleek sedans to luxurious SUVs to meet all your luxury needs.",
    },
    {
      id: 6,
      title: "What is the most expensive car to rent?",
      info: "The most expensive car to rent is typically a Rolls Royce or a Bentley, with rental prices reaching up to INR ₹4,00,000 per day.",
    },
  ];
  
  export default questions;
  