// export const api_url = "http://13.200.44.134"
// export const api_url = "http://localhost:5001"
// export const api_url = "http://0.0.0.0:5001"
// export const api_url = "http://13.200.44.134"
// export const api_url = "https://spotastro.in"
export const api_url = "https://api.goblck.in"
// export const api_url = "http://13.200.44.134"
// export const api_url = "http://54.147.142.185:5001"
// export const api_url = "http://0.0.0.0:5001"
// export const aws_bucket_url = "https://blck-luxurys.s3.ap-south-1.amazonaws.com"
export const aws_bucket_url = "https://blckluxury-assets.blr1.cdn.digitaloceanspaces.com/blckluxury-assets"
export const json_server_url = "http://localhost:3005"   
// export const json_server_url = "https://blck-luxurys.s3.ap-south-1.amazonaws.com"    
// export const json_server_url = "https://spotastro.in"
