import React, { useRef, useState } from 'react'
import s from './airportModal.module.css'
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import c from '../../../assets/css/custom.module.css'
import { indFormat } from '../../../helpers/IndCurrencyFormat';
import axios from '../../../helpers/Axios';
import feesData from '../../../fees.json'
import dayjs from 'dayjs';
// import { DesktopTimePicker } from '@mui/lab';
// import { TextField } from '@mui/material';
import { TimePicker, ConfigProvider } from 'antd';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { TextField } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
// import { ThemeProvider, createTheme } from '@mui/lab';
import { api_url, aws_bucket_url, json_server_url } from '../../../helpers/base_url';
import moment from 'moment';
import CryptoJS from 'crypto-js'
import TimeSelector from './DigitalClock';

function AirportDateModal({ setIsModalOpen, carName, selectedPackage, useType, details }) {
    // console.log(details.finalprice,"detailssssssssssssss")
    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    let nav = useNavigate()
    let modal = useRef()
    let leadTime = useRef()
    const location = useLocation();
    let startTimeRef = useRef()
    let startDateRef = useRef()
    const [showPopup, setShowPopup] = useState(false);

    const [fees, setFees] = useState(feesData.fees[0])
    
    const [data, setData] = useState({
        "date": dayjs().$H > 1 ? dayjs().$d.toLocaleDateString().split('/').join('-') : dayjs().$d.toLocaleDateString().split('/').join('-'),
        // "time": dayjs().$d.toTimeString().toString().split(' ')[0].match(/(\d\d:\d\d)/gm)[0],
        "time":'',
        "startDate": dayjs().$d.toLocaleDateString().split('/').join('-'),
        // "startTime": dayjs().add(4, 'hour').$d.toTimeString().toString().split(' ')[0],
        "startTime":'',
        "flightNo": "",
        "pickupDrop": "",
        "price": details.finalprice,
        "deposit": details.package.deposit,
        "driverAllowance": 0,
        "toll": details.package.toll_fee,
        "parking": details.package.parking_fee,
        "name": details.car.car_brand + '-' + details.car.car_model,
        "carId": details.car.id,
        "img": details.car.car_image
    })
    const [timeStamp, setTimeStamp] = useState({
        lead4hrs: ''
    })
    const [clean, setClean] = useState([])
    const [refDate, setRefDate] = useState(dayjs())
    // const [newDate, setNewDate] = useState(dayjs().$H > 19 ? dayjs().add(1, 'day') : dayjs())
    // const [newTime, setNewTime] = useState(dayjs().add(4, 'hour'))
    const [newDate, setNewDate] = useState(dayjs().$H > 1 ? dayjs() : dayjs())
    const [newTime, setNewTime] = useState(dayjs().add(4, 'hour'))
    const [early, setEarly] = useState(false)
    const [lead, setLead] = useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
        try {
            modal.current.classList.remove(`${s.slideOutTop}`);
            modal.current.classList.add(`${s.slideInTop}`);
        } catch (error) { }

        // document.body.style.overflow = 'hidden';

        axios.get(`${api_url}/airport`)
            .then((res) => {
                setClean(res.data)
            })

        return () => {
            document.body.style.overflowY = 'scroll';
        }
    }, [location]);
    function post_payment(){
        const time=data.startTime
        const [hours, minutes] = time.split(':')
console.log(hours,minutes,'hoii');
        function dictToURI(dict) {
            var str = [];
            for (var p in dict) {
                const stringValue = dict[p].toString();


                const secretKey = process.env.REACT_APP_API_KEY
                const encryptedData = CryptoJS.AES.encrypt(p, 'secretKey').toString();
                const encryptedData1 = CryptoJS.AES.encrypt(stringValue, 'secretKey').toString();
                str.push(encodeURIComponent(encryptedData) + "=" + encodeURIComponent(encryptedData1));
            }

            return str.join("&");
        }
        if(data.startTime === ''){
            // console.log('darun',data)
            startTimeRef.current.innerHTML = 'Enter Time!'
        }else if(data.date === '') {
            startDateRef.current.innerHTML = 'Enter Date!'

        }
        else{
        data.price = data.price
        // console.log('darun',data)
        nav(`/luxury-car-rental-in-${localStorage.City}/summary-airport?${dictToURI(data)}`,{state:data})
        //  nav('/payments',{state:data})
        // if (data.date === '') alert('Enter date!')
        // else if (data.time === '') alert('Enter time!')
        // else {
            localStorage.setItem('price', JSON.stringify(data));
            postdata()
        // }
        // axios.post(`${api_url}/create_order`, data)
        // .then(() => { nav(`/summary/${details.car.id}`) })
        // .catch((err) => { console.log(err); })
    }
    }
    // console.log('arun',details.package)
    useEffect(() => {
        // adding lead time of 4 hrs to timestamp
        setTimeStamp({ ...timeStamp, lead4hrs: refDate.add(4, 'hour') });
        const time=data.startTime
        const [hours, minutes] = time.split(':')
        // setting driver allowance
        const newAllowance = hours>= 13 && hours <= 24 || hours >= 0 && hours < 8 ?
            details.beta :
            0;
        setData(prevData => ({ ...prevData, driverAllowance: newAllowance }));
    
        // handling early morning bookings
        if ((dayjs().$D + 1 === newDate.$D || dayjs().$D === newDate.$D) && dayjs().$H + 4 >= 22 && newTime.$H < 10) {
            leadTime.current.innerHTML = 'Early morning booking not possible! select time after 7 AM.';
            setData(prevData => ({ ...prevData, time: '' }));
        } else {
            leadTime.current.innerHTML = '';
        }
    }, [newDate, newTime]);
    
    useEffect(() => {
        // adding lead time of 4 hrs to timestamp
        setTimeStamp({ ...timeStamp, lead4hrs: refDate.add(4, 'hour') });
        const time=data.startTime
        const [hours, minutes] = time.split(':')
        // setting driver allowance
        const newAllowance = hours>= 13 && hours <= 24 || hours >= 0 && hours < 8 ?
            details.beta :
            0;
        setData(prevData => ({ ...prevData, driverAllowance: newAllowance }));
    
        // handling early morning bookings
        if ((dayjs().$D + 1 === newDate.$D || dayjs().$D === newDate.$D) && dayjs().$H + 4 >= 22 && newTime.$H < 10) {
            leadTime.current.innerHTML = 'Early morning booking not possible! select time after 7 AM.';
            setData(prevData => ({ ...prevData, time: '' }));
        } else {
            leadTime.current.innerHTML = '';
        }
    }, []);
    
    
   
    function postdata() {
        for (let i = 0; i < clean.length; i++) {
            try {
                axios.delete(`${api_url}/summary/${clean[i].id}`)
            } catch (error) {
                // console.log('not deleted');
            }
        }
        if (data.date == '') alert('Enter Date!')
        else if (newDate.$D < dayjs().$D) alert("Selected Date Cannot be less than today's")
        else if (data.startTime == '') alert('Enter Time!')
        else if (lead) alert("Give 4 Hrs Lead Time")
        else if (early) alert("Early morning booking is not possible after 10 PM.")
        else {
            axios.post(`${api_url}/summary-airport`, data)
                .then(() => { nav(`/summary-airport/${details.car.id}`) })
                // .catch((err) => { console.log(err); })
        }
    }
    const disabledHours = () => {
        const currenttime=moment().hours()
        if(newDate <= dayjs()){
                
                return [...Array(currenttime + 4).keys()];
        }
        if(currenttime >=22){
            const runtime=data.date
            let todaydate=runtime.match(/-(\d+)-/)[0].replaceAll("-","")
           
            if(todaydate<= ((dayjs().$D)+1))
            {
                return [...Array(10).keys()];
            }
    }
              };
            // console.log(data)
            const handleDateChange = (newValue) => {
             
                if (newValue.$D == dayjs().$D && newTime < dayjs().add(4, 'hour')) {
                    setNewTime(dayjs().add(4, 'hour'))
                }
                setNewDate(newValue);
                setData({ ...data, startDate: dayjs(newValue).format('DD-MM-YYYY') });
                setShowPopup(true);
            };
        
            const handleTimeSelect = (selectedTime) => {
                setNewTime(selectedTime);
                const [hours, minutes] = selectedTime.split(':')
                setData({ ...data, startTime: selectedTime });
            };

    return (
        <div className={s.mainSection}>
            <div ref={modal} className={s.modalSection}>
                <div className={s.topSection}>
                    <div className={`${c.prefixed} ${s.modalHeading}`}>
                        <u>{useType}</u>
                        <div className={`${s.closeBtn}`}
                            onClick={() => {
                                try {
                                    modal.current.classList.remove(`${s.slideInTop}`)
                                    modal.current.classList.add(`${s.slideOutTop}`)
                                } catch (error) { }
                                setTimeout(() => {
                                    setIsModalOpen(false);
                                }, 200)
                            }}>
                            <img src={require('../../../assets/images/icons/close-btn-white.png')} alt="Close-btn" width={48} height={48} />
                        </div>
                    </div>
                    <div className={`${s.modalLandingImage}`}>
                        {/* <img src={require(`../../../assets/images/carimages/${details.car.car_image}`)} alt="Car-Image" /> */}
                        <img src={`${aws_bucket_url}/${details.car.car_image}`} alt="Car-Image" />

                    </div>
                </div>
                <div className={s.bottomSection}>
                    <div className={s.carInfoDetails}>
                        <div className={s.nameInfo}>
                            <span className={`${c.bigText} ${c.b500}`}>{carName}</span>
                            <span className={c.smallText}>{selectedPackage || 'Airport Pickup/Drop'}</span>
                        </div>
                        <div className={`${s.priceInfo} ${c.secondaryColor}`}>{
                            indFormat.format(
                                data.price +
                                data.driverAllowance +
                                data.parking +
                                data.toll)
                        }</div>
                    </div>
                    <div className={s.dateTimeSection}>
                        <div>
                            <span className={c.smallText}>Select Date</span>
                            <ThemeProvider theme={darkTheme}>
                            <MobileDatePicker
                            value={newDate}
                            format="DD-MM-YYYY"
                            disablePast
                            minDate={dayjs().$H > 19 ? dayjs().add(1, 'day') : dayjs()}
                            renderInput={(params) => <TextField{...params} fullWidth />}
                            onChange={(newValue) => {
                                setNewDate(newValue);
                                setData({ ...data, startDate: dayjs(newValue).$d.toLocaleDateString().split('/').join('-') })
                                // setData({ ...data, dateonly: dayjs(newValue).$D })
                                if (newValue.$D == dayjs().$D && newTime < dayjs().add(4, 'hour')) {
                                    let x = 'choose time'
                                    setNewTime(dayjs().add(4, 'hour'))
                                }setShowPopup(true);
                            }}
                        />
                            </ThemeProvider>
                            <div ref={startDateRef} className={`${c.tinyText} ${c.secondaryColor}`}></div>
                        </div>
                        <div className={c.sm}>
                            <span className={c.smallText}>Select Time</span>
                            <ThemeProvider theme={darkTheme}>
                            <ConfigProvider>
                                
                                <TimeSelector showPopup={showPopup} setShowPopup={setShowPopup} onTimeSelect={handleTimeSelect} dat={newDate} />
                                

                            </ConfigProvider>
                            </ThemeProvider>
                            <div ref={startTimeRef} className={`${c.tinyText} ${c.secondaryColor}`}></div>

                            <div ref={leadTime} className={`${c.tinyText} ${c.secondaryColor}`}></div>
                        </div>
                        <div className={s.radioInput}>
                            <input type="radio" name='airportPickupDrop' value={'Pickup'}
                                onChange={(e) => {
                                    setData({ ...data, pickupDrop: e.target.value })
                                }} style={{ height: '20px', width: '20px' }} />
                            <label htmlFor="airportPickupDrop">Airport Pickup</label>
                        </div>
                        <div className={s.radioInput}>
                            <input type="radio" name='airportPickupDrop' value={'Drop'}
                                onChange={(e) => {
                                    setData({ ...data, pickupDrop: e.target.value })
                                }} style={{ height: '20px', width: '20px' }} />
                            <label htmlFor="airportPickupDrop">Airport Drop</label>
                        </div>
                    </div>
                    <div className={s.flightNumberInput}>
                        <input type="text" name='flightNumber' onChange={(e) => { setData({ ...data, flightNo: e.target.value }) }} placeholder='Enter Flight Number' />
                    </div>
                    <div className={s.btnSection}>
                        <div className={c.extraSmallText}>* Minimum lead time is 4hrs.</div>
                        <div className={c.extraSmallText}>* Driver Batta applicable for rides between <span className={c.secondaryColor}>9 pm – 7 am</span>.</div>
                        <div className={c.extraSmallText}>* Distance and Time is calculated from garage to garage.</div>
                        <div>
                            <button className={`${c.continueBtnFilled}`} onClick={post_payment}>Continue</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AirportDateModal