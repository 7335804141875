import React from 'react';
import checkmark from './../../assets/images/logo/check-tick-icon-14145.png'; // update the path to your image
import './ThankYou.css';

const ThankYou = () => {
  return (
    <div className="container">
      <h1 className="title">Thank You !</h1>
      <p className="message">
      Thank you for booking with <span className="highlight">Blck Luxury.</span>  We are excited to serve you.
      </p>
      <img src={checkmark} alt="Checkmark" className="checkmark" />
      <h2 className="checkEmail">Check your Email</h2>
      <p className="contact">
      If you didn't receive the mail kindly contact on <span style={{color:'black'}}>+91-9900085611</span> or <a href="mailto:Contact@goblck.in">Contact@goblck.in</a>
      </p>
    </div>
  );
};

export default ThankYou;
