import React, { useEffect, useState } from 'react'
import s from '../profile.module.css'
import c from '../../../assets/css/custom.module.css'
import { ContextLogin, UserId, PrevUrl } from '../../../helpers/context'
 import axios from '../../../helpers/Axios'
// import axios from 'axios'
// import axios from '../../helpers/Axios'
import toast, { Toaster } from 'react-hot-toast';
import { useContext } from 'react'
import { api_url, json_server_url } from '../../../helpers/base_url'
import { useLocation } from 'react-router-dom'
import { Alert } from 'antd'
function ProfileTab() {
    const [auth, setAuth] = useState()
    const [userId, setUserId] = useContext(UserId);
    const [user, setUser] = useState([])
    const [prevUrl, setPrevUrl] = useContext(PrevUrl)
    const [data, setData] = useState([])
    const [loaded, setLoaded] = useState(false)
    const [userData, setUserData] = useState([])
    const [firstName, setFirstName] = useState(user.first_name);
    const [lastName, setLastName] = useState(user.last_name);
    const [email, setEmail] = useState(user.email);
    const [phone, setPhone] = useState(user.phone);
    const [usedid, setUsedid] = useState(user.usedid);
    let location = useLocation()
    // console.log(usedid,"nameeeeeeeeeeeeeeeee");

    useEffect(() => {

       
       
           




        // // axios.get(`${json_server_url}/login`)
        // axios.get(`${json_server_url}/login`)
        //     .then((res) => {
        //         setData(res.data[0]);

        //         if (res.data[0].auth == false) {
        //             console.log('Login to see your profile!');
        //             setPrevUrl(location.pathname);
        //         }
        //         else {
        //             axios.get(`/getusers?id=${res.data[0].id}`)
        //                 .then((res) => {
        //                     setUser(res.data.user[0])
        //                     console.log(res.data.user[0]);
        //                     setLoaded(true)
        //                 })
        //                 .catch((err) => {
        //                     console.log(err);
        //                 })
        //         }
        //     })
        //     .catch((err) => { console.log(err); })

        // let auth = sessionStorage.getItem('auth');

    }, [])

    useEffect(() => {
        // setAuth(sessionStorage.getItem('auth'));
        // if (!auth) {
        //     console.log('Login to see your profile!');
        // }
        // else {
            axios.get(`/user_information?id=${localStorage.USER_ID}`)
                .then((res) => {
                    setUser(res.data.user[0])
                    //  console.log(res.data.user[0],"66666666666666666666666");
                     setFirstName(res.data.user[0].firstname)
                     setLastName(res.data.user[0].lastname)
                     setEmail(res.data.user[0].email)
                     setPhone(res.data.user[0].contact)
                     setUsedid(res.data.user[0].id)
                    
                     
                })
                .catch((err) => {
                    console.log(err);
                })
        // }
    }, [])
    const submitValue = () => {
        const frmdetails = {
            'First Name': firstName,
            'Last Name': lastName,
            'Phone': phone,
            'Email': email,
        }
        // console.log(frmdetails);
      }
    async function Profileupdate() {

        const frmdetails = {
            "contact": 9597958240,
            "email": email,
            "firstname": firstName,
            "id": usedid,
            "lastname": lastName,
          }
          if(usedid == localStorage.USER_ID){
            axios
            .put(`/user_information?id=${usedid}`,frmdetails)
            .then((res) => {
              toast.success('updated')
            //   console.log(res)
            })


            localStorage["USER_ID"]=usedid
            localStorage["USER_firstname"]=firstName
            localStorage["USER_email"]=email
            localStorage["USER_lastname"]=lastName
   
            }    


          

        //   if (frmdetails.Email != localStorage.USER_email && firstName != localStorage.USER_firstname && lastName != localStorage.lastName) {
        //     if(usedid == localStorage.USER_ID){
        //         // localStorage["USER_ID"]=usedid
        //         // localStorage["USER_firstname"]=firstName
        //         // localStorage["USER_email"]=email
        //         // localStorage["USER_lastname"]=lastName
        // alert("updated")
        //         }    
        // }
        // else{
        //     alert("details are same")

        // }

       

    // const result = await axios.post(`${api_url}/user_information`,frmdetails);
    // console.log('data-------->',result)
    // alert('button clicked')
    // const profile = result.config.data;
    // console.log('Profile data',profile)
    }
    // console.log(user);
    return (
        <div className={s.profileTabSection}>
             <Toaster />
            <div className={`${s.inputs}`}>
                <label>First Name</label>
                {/* <input type="text" defaultValue={user.first_name} /> */}
                <input type="text" value={firstName} onChange={e => setFirstName(e.target.value) } />
            </div >
            <div className={`${s.inputs}`}>
                <label>Last Name</label>
                {/* <input type="text" defaultValue={user.last_name} /> */}
                <input type="text" value={lastName} onChange={e => setLastName(e.target.value)} />
            </div>
            <div className={`${s.inputs}`}>
                <label>Email</label>
                {/* <input type="email" defaultValue={user.email} /> */}
                <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
            </div>
            <div className={`${s.inputs}`}>
                <label>Phone</label>
                {/* <input type="text" defaultValue={user.phone} /> */}
                <input type="text" readonly="readonly" defaultValue={localStorage.USER_PHONE_NO}  />
            </div>
            {/* <div className={`${s.uploadSection}`}>
                <div>
                    <span className={`${c.smallText}`}>Driver's License</span>
                    <span className={`${c.smallText} ${c.secondaryColor}`}>Add License</span>
                </div>
                <label className={s.fileUpload}>
                    <input type="file" />
                    <span>
                        <img src={require('../../../assets/images/icons/camera-icon.png')} alt="cameraIcon" width={30} height={30} />
                    </span>
                </label>
            </div>
            <div className={`${s.uploadSection}`}>
                <div>
                    <span className={`${c.smallText}`}>Aadhar Card</span>
                    <span className={`${c.smallText} ${c.secondaryColor}`}>Add Card</span>
                </div>
                <label className={s.fileUpload}>
                    <input type="file" />
                    <span>
                        <img src={require('../../../assets/images/icons/camera-icon.png')} alt="cameraIcon" width={30} height={30} />
                    </span>
                </label>
            </div> */}
            <button className={c.primaryBtnFilled}onClick={() => Profileupdate()}>Update</button>
            
        </div>
    )
}

export default ProfileTab