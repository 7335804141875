
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from '../../../helpers/Axios'
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import s from "./update.module.css"
import {
  Card,
  CardBody,
  CardHeader,
  Input,
  Label,
  Col,
  Collapse,
  Container,
  Row,
  DropdownItem,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownToggle,
  DropdownMenu,
  UncontrolledDropdown,
  CardFooter,
} from "reactstrap";
import {carname} from "./upcoming";
function BookingsModal01({
  setShow,
  
  show,
  id,
  carname,
  payment,
  type01,
  date01,
  time01,
  price01,
  agentname,
  location,
  email,
  status,
  alter_email,
  // alter_email2,
  phone,
  alt_phone,
}) {
  //   const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 const [reson,setReason] =useState()
  const [reportingdate, setReportingdate] = useState(new Date());
  const [reportingtime, setReportingtime] = useState();
  const useCurrentCallback = (callback) => {
    const reference = React.useRef();
    reference.current = callback;
    return (...args) => {
      return reference.current?.(...args);
    };
  };

  const now = new Date();
  // console.log('Current date: ' + now.toDateString());
  // console.log('Current time: ' + now.toTimeString()); 
  const time = now.toLocaleTimeString();
  const date = now.toLocaleDateString();



  const [reciveData, setReceiveData] = useState([]);

  const showToastMessageUploadedSuccess = () => {
    toast.success("Updated Successfully !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const showToastMessageErroruploading = () => {
    toast.error("Error Updating !", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

function driversend(){
const a=3333

const q=

  `Greetings from BLCK!\tPlease find the your chauffeur and vehicle details below \n 
  Chauffeur name=${a} \n 
  Contact number=${a}\n
   Alternative number=${a} \n

  Vehicle number=${a} \n 
  Reporting Time=${a} \n 
  Cheers\n 
  Team BLCK`
  



  // axios.get(`https://api.mylogin.co.in/api/v2/SendSMS?ApiKey=8NzlHH40L/AH3MR83XpYmT5d1EQVofsbB+Vx1jxqUcY=&ClientId=e649ffb9-407e-49ec-8155-8eba78e30620&SenderId=BLKLUX&Message=Customer OTP verfication for Blck luxury!, Your otp is\n ${q} Happy driving with Blck Luxury!.&MobileNumbers=${8675036284}`)
   
  toast.success('Sms send...')
}
// console.log(reson,reciveData,reportingtime,"yyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy")
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className={s.modalheader}>
          {/* <Modal.Title>Greetings from BLCK!
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body className={s.modelbody}>
          <Form className={s.modalcontent}>
            <Form.Group
              className={`mb-3 ${s.formContainer}`}
              controlId="exampleForm.ControlInput1"
            >
              <div className={`auth-page-content ${s.formBackground}`}>
                <Container fluid className={s.conentcontainer}>
                  <Row>
                    <Col xxl={12}>
                      <Card className={s.card}>
                        <CardBody className="p-7">
                          <div>
                            <div className="p-2 mt-0 justify-content-center">
                              <Form
                                onSubmit={(e) => {
                                  e.preventDefault();
                                }}
                                action="#"
                              >
                                {/* <Row>
                                  <Col lg={6}>
                                    <div className={s.mb}>
                                      <Label
                                        htmlFor="chauffeur_name"
                                        className="form-label"
                                      >
                                      Price: 
                                      {" "}
                                      </Label>
                                    
                                      <Input
                                        value={price01}
                                        id="chauffeur_name"
                                        name="chauffeur_name"
                                        className={s.formcontrol}
                                        placeholder="Enter Chauffeur Name:"
                                        type="text"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="contact_number"
                                        className="form-label"
                                      >
                                     Type :
                                      {" "}
                                      </Label>
                                      <Input
                                        value={type01}
                                        id="contact_number"
                                        className={s.formcontrol}
                                        name="contact_number"
                                        type="text"
                                        placeholder="Enter Contact Number"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="alternative_number"
                                        className="form-label"
                                      >
                                     Payment Status:
                                      {" "}
                                      </Label>
                                      <Input
                                        value={payment}
                                        id="alternative_number"
                                        className={s.formcontrol}
                                        name="alternative_number"
                                        type="text"
                                        placeholder="Enter Alternative Number"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className="mb-2">
                                      <Label
                                        htmlFor="vehicle_number"
                                        className="form-label"
                                      >
                                      Vehicle name :
                                      {" "}
                                      </Label>
                                      <Input
                                        value={carname}
                                        id="vehicle_number"
                                        className={s.formcontrol}
                                        name="vehicle_number"
                                        type="text"
                                        placeholder="Enter Vehicle Number"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                <Col lg={6}>
                                    <div className="mb-2">
                                      <Label htmlFor="reporting_date" className="form-label">
                                        Reporting Date:
                                      </Label>
                                      <DatePicker
                                        id={s.reporting_date}
                                        name="reporting_date"
                                        // popperClassName=
                                        calendarClassName={s.datecontrol}
                                        placeholderText="Select Date"
                                        selected={reportingdate}
                                        onChange={date => setReportingdate(date)}
                                        className="form-control"
                                        dateFormat="MM/dd/yyyy"
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={6}>
                                    <div className={s.mb-2}>
                                      <Label htmlFor="reporting_time" className="form-label">
                                        Reporting Time:
                                      </Label>
                                      <TimePicker
                                        id={s.reporting_time}
                                        name="reporting_time"
                                        value={time01}
                                        defaultValue={new Date(reportingtime)}
                                        onChange={time => setReportingtime(time)}
                                      />
                                    </div>
                                  </Col>
                                </Row> */}
                              </Form>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
                <p className={s.title}>Please give us  a reason for your of Cancellation</p>
                <Input className={s.notecontent} type="textarea"  onChange={e=>setReason(e.target.value)}></Input>
                <ToastContainer/>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer className={s.modalfooter}>
          <Button
            variant="success"
            className={s.sendbutton}
            onClick={driversend}
            //   let payload = {
            //     agentname: updatedagentname,
            //     id: id,
            //     location: updatedlocation,
            //     email: updatedemail,
            //     alter_email: updatedaltemail,
            //     // alter_email2: updatedaltemai2,
            //     phone: updatedphn,
            //     status: updatedstatus,
            //     alt_phone: updatedaltphn,
            //   };
            //   axios
            //     .put("/updateAgentslist", payload)
            //     .then((res) => {
            //       // setIsOpen(false)
            //       showToastMessageUploadedSuccess();
            //     })
            //     .catch((err) => {
            //       // setIsOpen(false)
            //       showToastMessageErroruploading();
            //     });
            // }}
          >
            Send
          </Button>

        {/*  <Button variant="secondary" onClick={handleClose}>
            Close
                              </Button>*/}
        </Modal.Footer>
      </Modal>
    </>
  );
}

// render(<ModalView />);
export default BookingsModal01;
