import React, { useContext, useEffect, useState } from 'react'
import { Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner } from 'reactstrap';
import s from './loginpage.module.css'
import c from '../../assets/css/custom.module.css'
import { auth } from './firebase.config';
// import PhoneInput from "react-phone-input-2";
import OtpInput from "otp-input-react";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
// import { ToastContainer, toast } from 'react-toastify';
import toast, { Toaster } from 'react-hot-toast';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { ContextLogin, UserId, PrevUrl } from '../../helpers/context'
import axios from '../../helpers/Axios';
import axiosInstance1 from 'axios';
import { json_server_url } from '../../helpers/base_url';
// export const user1 = 


function Adminpage() {
  const [isLogged, setIsLogged] = useContext(ContextLogin)
  const [userId, setUserId] = useContext(UserId)
  const [prevUrl, setPrevUrl] = useContext(PrevUrl)
 
  //useStates
  const [showotp, setShowotp] = useState(false)
  const [otp, setOtp] = useState("");
  const [ph, setPh] = useState("");
  const[contact,setContact]=useState("")
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [clean, setClean] = useState([])
  const [randomOTP, setRandomOTP] = useState("");
  const [recive, setRecive] = useState([]);
  const[data,setData] = useState({phone: "", email: "",firstname: "",lastname:"", id:""});
const[username,setUsername]=useState("");
const [password,setPassword]=useState("");
const [passwordShow, setPasswordShow] = useState(false);

  let nav = useNavigate()


  async function otpauth(){
   
    axios
    .get(`/user_information?contact=${contact}`)
    .then((res) => {
      setRecive(res.data.user);
      console.log(res.data.user[0]);
      setData({...data,phone: res.data.user[0].contact,
        email: res.data.user[0].email,
        firstname: res.data.user[0].firstname,
        lastname: res.data.user[0].lastname,
        id: res.data.user[0].id});
      })
    
}

    // Example usage
   
   
  const [userData, setUserData] = useState(null)
  

  function handleSubmit(){
       
    if(username == 'admin@gmail.com' && password == "admin"){
        sessionStorage.setItem('user','admin')
      localStorage["USER_firstname"]=""
      localStorage["USER_PHONE_NO"]=""
    localStorage["USER_ID"]=""
    localStorage["USER_email"]=""
    localStorage["USER_lastname"]=""
      nav('/')
  }
}



  return (
    <div className={`${s.logingSection}`}>
      <Toaster />
      <main className={`${s.container}`}>
      <div className="text-center mt-2">
      <h5 className="text-primary">Welcome Back !</h5>
      <p className="text-muted">Sign in to continue to Blck Luxury.</p>
  </div>
  <div className="p-2 mt-4">
                                            <Form
                                                onSubmit={(e) => {
                                                    e.preventDefault();
                                                    handleSubmit();
                                                    return false;
                                                }}
                                                action="#">

                                                <div className="mb-3">
                                                    <Label htmlFor="email" className="form-label">Email</Label>
                                                    <Input
                                                        name="email"
                                                        className="form-control"
                                                        placeholder="Enter email"
                                                        type="email"
                                                        onChange={(e)=>{setUsername(e.target.value)}}
                                                        // onBlur={validation.handleBlur}
                                                        //  value={validation.values.email || ""}
                                                        // invalid={
                                                        //     validation.touched.email && validation.errors.email ? true : false
                                                        // }
                                                    />
                                                    {/* {validation.touched.email && validation.errors.email ? (
                                                        <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                    ) : null} */}
                                                </div>

                                                <div className="mb-3">
                                                    <div className="float-end">
                                                        <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                                                    </div>
                                                    <Label className="form-label" htmlFor="password-input">Password</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <Input
                                                            name="password"
                                                            // value={validation.values.password || ""}
                                                            type={passwordShow ? "text" : "password"}
                                                            className="form-control pe-5"
                                                            placeholder="Enter Password"
                                                            onChange={(e)=>{setPassword(e.target.value)}}
                                                            // onChange={validation.handleChange}
                                                            // onBlur={validation.handleBlur}
                                                            // invalid={
                                                            //     validation.touched.password && validation.errors.password ? true : false
                                                            // }
                                                        />
                                                        {/* {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                        ) : null} */}
                                                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                </div>

                                                <div className="form-check">
                                                    <Input className="form-check-input" type="checkbox" value="" id="auth-remember-check" />
                                                    <Label className="form-check-label" htmlFor="auth-remember-check">Remember me</Label>
                                                </div>

                                                <div className="mt-4">
                                                    <Button color="success" className="btn btn-success w-100" type="submit">
                                                        {/* {error ? null : loading ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} */}
                                                        Sign In
                                                    </Button>
                                                </div>

                                               
                                            </Form>
                                        </div>
        
       
      </main>
    </div>
  )
}

export default Adminpage